import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        settings: [],
    },
    getters: {
        getSettings (state) {
            var settings = [];
            Object.keys(state.settings).forEach(key => {
                settings.push({ id: state.settings[key], description: key });
            });

            return settings;
        }
    },
    actions: {
        retrieveNotifications () {
            return new Promise((resolve) => {
                api.settings.listNotifications(result => {
                    resolve(result);
                });
            });
        },
        newNotification ({ dispatch }, notification) {
            return new Promise((resolve) => {
                api.settings.newNotification(notification, () => {
                    dispatch('notifications/queueNotification', {
                        message: 'Notification created successfully',
                        extraClass: 'bg-green-500 text-white'
                    }, { root: true });
                    resolve();
                });
            });
        },
        updateNotification ({ dispatch }, notification) {
            return new Promise((resolve) => {
                api.settings.updateNotification(notification, () => {
                    dispatch('notifications/queueNotification', {
                        message: 'Notification updated successfully',
                        extraClass: 'bg-green-500 text-white'
                    }, { root: true });
                    resolve();
                });
            });
        },
        deleteNotification ({ dispatch }, notification) {
            return new Promise((resolve) => {
                api.settings.deleteNotification(notification, () => {
                    dispatch('notifications/queueNotification', {
                        message: 'Notification deleted successfully',
                        extraClass: 'bg-green-500 text-white'
                    }, { root: true });
                    resolve();
                });
            });
        },
        changePassword ({ dispatch }, passwords) {
            return new Promise((resolve) => {
                api.settings.changePassword(passwords, result => {
                    dispatch('notifications/queueNotification', {
                        message: 'Password has been updated',
                        extraClass: 'bg-green-500 text-white'
                    }, { root: true });
                    resolve(result);
                });
            });
        },
        updateUser ({ dispatch }, user) {
            return new Promise((resolve) => {
                api.settings.updateUser(user, () => {
                    dispatch('authentication/getUserDetails', {}, {
                        root: true,
                    });
                    dispatch('notifications/queueNotification', {
                        message: 'User has been updated',
                        extraClass: 'bg-green-500 text-white'
                    }, { root: true });
                    resolve();
                });
            });
        }
    },
    mutations: {
        setNotifications (state, settings) {
            state.settings = settings;
        }
    }
};