<template>
  <div class="z-50 fixed top-0 left-0 right-0 flex flex-col items-center justify-center">
    <transition-group name="fade">
      <div v-for="notification in notifications" :key="notification.id" :class="notification.extraClass" class="mx-auto mt-2 rounded p-4 shadow">{{ notification.message}}</div>
    </transition-group>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        notifications: state => state.notifications.notifications
      })
    }
  }
</script>
