import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        versions: []
    },
    actions: {
        retrieveVersions ({ commit }, { instanceId }) {
            api.data.get(instanceId, 'integration/versions', result => {
                commit('setVersions', result);
            });
        }
    },
    mutations: {
        setVersions (state, versions) {
            state.versions = versions;
        }
    }
};
