import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        items: [],
        current: null,
        paging: {
            total: 0,
            page: 1,
            pageSize: 15,
        }
    },
    actions: {
        retrieveWorkflows ({ commit }, { instanceId, page, pageSize, adapter, entityType }) {
            api.data.list(instanceId, 'workflow', page, pageSize, {
                adapter_id: adapter,
                entity_type_id: entityType
            }, result => {
                const meta = result.meta || result;

                const [total, pageSize, currentPage] = [meta.last_page, meta.per_page, meta.current_page];
                commit('setPaging', { total, pageSize, currentPage });
                commit('setAll', result.data);
            });
        },
        retrieveWorkflowDetails ({ commit }, { instanceId, workflowId }) {
            commit('setCurrent', null);
            return new Promise((resolve) => {
                api.data.get(instanceId, `workflow/${workflowId}`, result => {
                    commit('setCurrent', result);
                    resolve();
                });
            });
        },
        retrieveWorkflowsForSelect (context, { instanceId }) {
            return new Promise((resolve) => {
                api.data.list(instanceId, 'workflow', 1, 100, {}, result => {
                    resolve(result.data);
                });
            });
        },
        createWorkflow ({ dispatch }, { instanceId, workflow }) {
            return new Promise((resolve) => {
                api.data.post(instanceId, 'workflow', workflow, result => {
                    dispatch('notifications/queueNotification', 'Workflow created', { root: true });
                    resolve(result);
                });
            });
        },
        saveWorkflow (context, {
            instanceId,
            workflow,
            diagramData,
            newItems,
            existingItems,
            deletedItems,
            sortOrder
        }) {
            return new Promise((resolve) => {
                api.data.put(instanceId, `workflow/${workflow.id}`, {
                    diagram: diagramData,
                    workflow,
                    newItems,
                    existingItems,
                    deletedItems,
                    sortOrder
                }, result => {
                    resolve(result);
                });
            });
        },
        deleteWorkflow (context, { instanceId, workflowId }) {
            return new Promise((resolve) => {
                api.data.delete(instanceId, `workflow/${workflowId}`, result => {
                    resolve(result);
                });
            });
        }
    },
    mutations: {
        setAll (state, workflows) {
            state.items = workflows;
        },
        setCurrent (state, workflow) {
            state.current = workflow;
        },
        setPaging (state, { total, pageSize, currentPage }) {
            state.paging = { total, pageSize, currentPage };
        }
    }
};
