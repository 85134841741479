import api from '../../services/api';

export default {
  namespaced: true,
  state: {
      items: []
  },
  actions: {
    autocompleteSuggestions (context, { type }) {
      return new Promise((resolve) => {
        api.metadata.getAutocompleteSuggestions(type, suggestions => {
          resolve(suggestions)
        })
      })
    },
    retrieveSuggestions ({ commit }, { instanceId }) {
      api.data.list(instanceId, 'suggestions', 1, 1, {  }, result => {
        commit('setAll', result)
      })
    }
  },
  mutations: {
    setAll (state, suggestions) {
      state.items = suggestions
    }
  }
}
