<template>
    <div id="navigator" class="flex">
        <div v-for="(breadcrumb, index) in breadcrumbs" :key="index"
             class="relative flex items-center text-gray-600 last-child:text-gray-300 hover:text-gray-300 cursor-pointer"
             @click="routeTo(breadcrumb)">
            <template v-if="index > 0">
                <div
                    style="position: absolute; border-right: 1px solid currentColor; height:50%; width: 0; top: 0; transform: rotate(-10deg)"></div>
                <div
                    style="position: absolute; border-right: 1px solid currentColor; height:50%; width: 0; bottom: 0; transform: rotate(10deg)"></div>
            </template>
            <span class="font-light text-lg mx-6">
              {{ breadcrumb?.name || breadcrumb }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    methods:    {
        routeTo (breadcrumb) {
            if (breadcrumb?.route) {
                this.$router.push({ name: breadcrumb.route });
            }
        }
    },
    computed:   {
        ...mapGetters({
            navigationStack: 'navigation/getNavigationStack',
            navigationTitle: 'navigation/getNavigationTitle'
        }),
        breadcrumbs () {
            let crumbs = this.navigationStack.map(stackItem => stackItem.meta.breadcrumbs).flat().map(rawCrumb => {
                if (rawCrumb) {
                    return rawCrumb;
                }
            });

            if (this.navigationTitle) {
                crumbs = [...crumbs, this.navigationTitle];
            }

            return crumbs.filter(crumb => crumb != null);
        }
    }
};
</script>

<style lang="scss" scoped>
#navigator {
    @media screen and (max-width: 800px) {
        display: none;
    }
}
</style>
