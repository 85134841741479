import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        all: [],
        adminAll: [],
        current: null,
        currentlyEditedInstance: null,
        paging: {
            total: 0,
            page: 1,
            pageSize: 15,
        },
        errors: null
    },
    getters: {},
    actions: {
        retrieveInstances ({ commit }) {
            return new Promise((resolve) => {
                api.instances.list(instances => {
                    commit('setAll', instances);
                    resolve();
                });
            });
        },
        retrieveAdminInstances ({ commit }, { page, pageSize }) {
            return new Promise((resolve) => {
                api.instances.adminList(page, pageSize, result => {
                    const meta = result.meta || result;

                    const [total, pageSize, currentPage] = [meta.last_page, meta.per_page, meta.current_page];
                    commit('setPaging', { total, pageSize, currentPage });
                    commit('setAdminAll', result.data);
                    commit('setCurrentlyEditedInstance', null);
                    resolve();
                });
            });
        },
        retrieveInstanceDetails ({ commit }, instanceId) {
            return new Promise((resolve) => {
                api.instances.get(instanceId, result => {
                    commit('setCurrentlyEditedInstance', result);
                    resolve();
                });
            });
        },
        createInstance ({ dispatch, commit }, instance) {
            return new Promise((resolve) => {
                api.instances.create(instance, result => {
                    resolve(result);
                    dispatch('notifications/queueNotification', 'Successfully created instance', { root: true });
                }, (error) => {
                    commit('setErrors', error.response.data.errors);
                });
            });
        },
        updateInstance ({ commit, dispatch }, instance) {
            return new Promise((resolve) => {
                api.instances.update(instance, result => {
                    commit('setCurrentlyEditedInstance', result);
                    dispatch('notifications/queueNotification', 'Successfully updated instance', { root: true });
                    resolve();
                });
            });
        },
    },
    mutations: {
        setAll (state, instances) {
            state.all = instances;
        },
        setAdminAll (state, instances) {
            state.adminAll = instances;
        },
        setCurrentInstance (state, instance) {
            state.current = instance;
        },
        setCurrentlyEditedInstance (state, instance) {
            state.currentlyEditedInstance = instance;
        },
        setPaging (state, { total, pageSize, currentPage }) {
            state.paging = { total, pageSize, currentPage };
        },
        setErrors (state, errors) {
            state.errors = errors;
        }
    }
};
