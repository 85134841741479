import useCan from "../composables/Auth/useCan";

const routes = [
    {
        path:     '/',
        redirect: (to) => {
            return {
                path: useCan('view administration') ? '/administration' : '/dashboard',
            };
        },
    },
    {
        path:      '/login',
        name:      'login',
        component: () => import(/* webpackChunkName: "Authentication_Login" */ '../views/Authentication/Login'),
        meta:      {
            secure: false,
            layout: 'authentication',
        },
    },
    {
        path:      '/password/forgot',
        name:      'forgot-password',
        component: () => import(/* webpackChunkName: "Authentication_ForgotPassword" */ '../views/Authentication/ForgotPassword'),
        meta:      {
            secure: false,
            layout: 'authentication',
        },
    },
    {
        path:      '/password/token/:token',
        name:      'setup-password',
        component: () => import(/* webpackChunkName: "Authentication_SetupPassword" */ '../views/Authentication/SetupPassword'),
        meta:      {
            secure: false,
            layout: 'authentication',
        },
    },
    {
        path:      '/password/token/:token/:email',
        name:      'setup-password-mail',
        component: () => import(/* webpackChunkName: "Authentication_SetupPassword" */ '../views/Authentication/SetupPassword'),
        meta:      {
            secure: false,
            layout: 'authentication',
        },
    },
    {
        path:      '/administration',
        name:      'administration',
        component: () => import(/* webpackChunkName: "Administration" */ "../views/Administration"),
        meta:      {
            secure:      true,
            breadcrumbs: [{ name: 'Administration' }],
        }
    },
    {
        path:      '/dashboard',
        name:      'dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard'),
        meta:      {
            secure:      true,
            breadcrumbs: [{ name: 'Dashboard' }],
        }
    },
    {
        path:      '/settings',
        name:      'settings.index',
        component: () => import(/* webpackChunkName: "Settings_Index" */ '../views/Settings/Index'),
        meta:      {
            secure:      true,
            breadcrumbs: [{ name: 'Settings' }],
        }
    },
    {
        path:      '/instances',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            // {
            //     path:      '',
            //     name:      'instance.index',
            //     component: () => import(/* webpackChunkName: "Instances_Index" */ '../views/Instances/Index'),
            //     meta:      {
            //         secure:      true,
            //         breadcrumbs: [{
            //             name: 'Instances'
            //         }],
            //     }
            // },
            {
                path:      '',
                name:      'instances.overview',
                component: () => import(/* webpackChunkName: "Instances_Index" */ '../views/Instances/Overview'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Instances'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'instances.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Instances/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Instances',
                            route: 'instances.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/teams',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'teams.index',
                component: () => import(/* webpackChunkName: "Teams_Index" */ '../views/Teams/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Teams'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'teams.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Teams/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Teams',
                            route: 'teams.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/messages',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'messages.index',
                component: () => import(/* webpackChunkName: "Messages_Index" */ '../views/Messages/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Messages'
                    }],
                }
            },
            {
                path:      ':guid',
                name:      'messages.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Messages/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Messages',
                            route: 'messages.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/mappings',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'mappings.index',
                component: () => import(/* webpackChunkName: "Mappings_Index" */ '../views/Mappings/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Mappings'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'mappings.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Mappings/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Mappings',
                            route: 'mappings.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/adapters',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'adapters.index',
                component: () => import(/* webpackChunkName: "Adapters_Index" */ '../views/Adapters/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Adapters'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'adapters.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Adapters/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Adapters',
                            route: 'adapters.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/workflows',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'workflows.index',
                component: () => import(/* webpackChunkName: "Workflows_Index" */ '../views/Workflows/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Workflows'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'workflows.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Workflows/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Workflows',
                            route: 'workflows.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/monitoring',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '/incidents',
                name:      'incidents.index',
                component: () => import(/* webpackChunkName: "Incidents_Index" */ '../views/Monitoring/Incidents/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'incidents'
                    }],
                }
            },
        ]
    },
    {
        path:      '/schedules',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'schedules.index',
                component: () => import(/* webpackChunkName: "Schedules_Index" */ '../views/Schedules/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Schedules'
                    }],
                }
            },
            {
                path:      ':id',
                name:      'schedules.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/Schedules/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Schedules'
                    }],
                }
            },
        ]
    },
    {
        path:      '/lookup-tables',
        component: () => import(/* webpackChunkName: "ViewContainer" */ '../views/ViewContainer'),
        children:  [
            {
                path:      '',
                name:      'lookup_tables.index',
                component: () => import(/* webpackChunkName: "LookupTables_Index" */ '../views/LookupTables/Index'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [{
                        name: 'Lookup Tables'
                    }],
                }
            },
            {
                path:      ':table',
                name:      'lookup_tables.details',
                component: () => import(/* webpackChunkName: "Detail_Views" */ '../views/LookupTables/Details'),
                meta:      {
                    secure:      true,
                    breadcrumbs: [
                        {
                            name:  'Lookup Tables',
                            route: 'lookup_tables.index'
                        }
                    ],
                }
            },
        ]
    },
    {
        path:      '/admin',
        name:      'admin.index',
        component: () => import(/* webpackChunkName: "Admin_Details" */ '../views/Admin/Index'),
        meta:      {
            secure:      true,
            breadcrumbs: [{
                name:  'Admin',
                route: 'admin.index'
            }],
        },
    },
];

export default routes;
