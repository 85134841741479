export default {
    namespaced: true,
    state: {
        navigationStack: [],
        navigationTitle: null,
    },
    getters: {
        getNavigationStack (state) {
            return state.navigationStack;
        },
        getNavigationTitle (state) {
            return state.navigationTitle;
        }
    },
    mutations: {
        updateNavigationStack (state, stack) {
            state.navigationStack = stack;
        },
        setNavigationTitle (state, title) {
            state.navigationTitle = title;
        }
    }
};
