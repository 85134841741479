import {createStore} from 'vuex'

import authentication from './modules/authentication';
import navigation     from './modules/navigation';
import notifications  from './modules/notifications';
import integration    from './modules/integration';
import provisioning   from './modules/provisioning';
import metadata       from './modules/metadata';
import dashboard      from './modules/dashboard';
import engine         from './modules/engine';
import instances      from './modules/instances';
import teams          from './modules/teams';
import messages       from './modules/messages';
import workflows      from './modules/workflows';
import mappings       from './modules/mappings';
import adapters       from './modules/adapters';
import lookupTables   from './modules/lookupTables';
import settings       from './modules/settings';
import products       from './modules/products';
import schedules      from "./modules/schedules";

var store = createStore({
	modules: {
		authentication,
		navigation,
		notifications,
		integration,
		provisioning,
		metadata,
		dashboard,
		engine,
		instances,
		teams,
		messages,
		workflows,
		mappings,
		adapters,
		lookupTables,
		settings,
		products,
		schedules
	}
});

export default store;
