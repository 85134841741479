import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        items: [],
        userRoles: [],
        current: null,
        paging: {
            total: 0,
            page: 1,
            pageSize: 15,
        },
        errors: {}
    },
    actions: {
        retrieveTeams ({ commit }, { page, pageSize }) {
            api.teams.list(page, pageSize, result => {
                const meta = result.meta || result;

                const [total, pageSize, currentPage] = [meta.last_page, meta.per_page, meta.current_page];
                commit('setPaging', { total, pageSize, currentPage });
                commit('setAll', result.data);
                commit('setCurrent', null);
            });
        },
        retrieveTeamDetails ({ commit }, teamId) {
            return new Promise((resolve) => {
                api.teams.get(teamId, result => {
                    commit('setCurrent', result);
                    resolve();
                });
            });
        },
        retrieveUserRoles ({ commit }) {
            return new Promise((resolve) => {
                api.teams.roles(result => {
                    commit('setUserRoles', result);
                    resolve();
                });
            });
        },
        createTeam ({ dispatch }, team) {
            return new Promise((resolve) => {
                api.teams.create(team, result => {
                    dispatch('notifications/queueNotification', 'Team successfuly created', { root: true });
                    resolve(result);
                });
            });
        },
        updateTeam ({ commit, dispatch }, { team, teamInstances }) {
            return new Promise((resolve) => {
                api.teams.update(team, teamInstances, result => {
                    dispatch('notifications/queueNotification', 'Team updated successfully', { root: true });
                    dispatch('instances/retrieveInstances', null, { root: true });
                    commit('setCurrent', result);
                    resolve();
                });
            });
        },
        createUser ({ dispatch, commit }, { team, user }) {
            return new Promise((resolve) => {
                api.teams.createUser(team, user, () => {
                    dispatch('notifications/queueNotification', 'User created successfully', { root: true });
                    resolve();
                }, (error) => {
                    commit('setErrors', error.response.data.errors);
                });
            });
        },
        updateUser ({ dispatch, commit }, { team, user }) {
            return new Promise((resolve) => {
                api.teams.updateUser(team, user, () => {
                    dispatch('notifications/queueNotification', 'User updated succesfully', { root: true });
                    resolve();
                }, (error) => {
                    commit('setErrors', error.response.data.errors);
                });
            });
        },
        deleteUser ({ dispatch }, { team, user }) {
            return new Promise((resolve) => {
                api.teams.deleteUser(team, user, () => {
                    dispatch('notifications/queueNotification', 'Successfully deleted user', { root: true });
                    resolve();
                });
            });
        }
    },
    mutations: {
        setAll (state, instances) {
            state.items = instances;
        },
        setCurrent (state, team) {
            state.current = team;
        },
        setPaging (state, { total, pageSize, currentPage }) {
            state.paging = { total, pageSize, currentPage };
        },
        setUserRoles (state, userRoles) {
            state.userRoles = userRoles;
        },
        setErrors (state, errors) {
            state.errors = errors;
        }
    }
};