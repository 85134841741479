import { createApp } from 'vue';
import './main.css';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import i18n from './i18n';
import './prototypes';

(async () => {
    const setupApp = () => {
        const app = createApp(App);

        app.config.globalProperties.moment = moment;
        app.config.unwrapInjectedRef = true;
        app.config.performance = true;

        app.use(router)
            .use(store)
            .use(i18n)
            .mount('#app');
    };

    try {
        await store.dispatch('authentication/getUserDetails');
    } catch (error) {
        console.warn('error?.response');
        console.warn(error?.response);

        setupApp();
    } finally {
        setupApp();
    }
})();
