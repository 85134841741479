<template>
    <global-data-provider>
        <div class="antialiased h-screen font-sans flex flex-col bg-gray-200">
            <top-bar/>
            <notifications/>

            <div class="flex flex-grow overflow-y-auto">
                <sidebar class="relative flex flex-shrink-0 flex-col w-20 sticky top-0 bg-gray-300 z-50"/>

                <main class="relative flex-grow text-gray-900 overflow-y-auto">
                    <router-view/>

                    <instance-data-popup v-if="can('manage instances')"/>
                </main>

                <version-checker/>
            </div>
        </div>
    </global-data-provider>
</template>

<script>
import {useStore}         from 'vuex';
import TopBar             from '../components/TopBar';
import Notifications      from '../components/Notifications';
import VersionChecker     from '../components/common/VersionChecker';
import InstanceDataPopup  from '../components/InstanceDataPopup';
import Sidebar            from '../components/Sidebar';
import {
    computed,
    provide,
    watch
}                         from 'vue';
import GlobalDataProvider from '../DataProviders/GlobalDataProvider';
import useCan             from "@/composables/Auth/useCan";

export default {
    name:       "DefaultLayout",
    components: {
        GlobalDataProvider,
        Sidebar,
        InstanceDataPopup,
        VersionChecker,
        Notifications,
        TopBar,
    },
    setup() {
        const store           = useStore();
        const currentInstance = computed(() => store.state.instances.current);
        const user            = computed(() => store.state.authentication);

        watch(() => currentInstance, () => {
            if (currentInstance.value?.id) {
                store.dispatch('messages/getMessageStatuses', currentInstance.value.id);
                store.dispatch('engine/getEntityTypes', {instanceId: currentInstance.value.id});
            }
        }, {
            deep:      true,
            immediate: true,
        });

        provide('currentInstance', currentInstance);
        provide('user', user);

        return {
            currentInstance,
            user,

            can: useCan,
        };
    },
};
</script>
