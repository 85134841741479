String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ucwords = function () {
    const words = this.split(" ");

    for (let i = 0; i < words.length; i++) {
        if (words[i]) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
    }

    return words.join(" ");
};

String.prototype.truncate = function (length = 30, symbol = '...') {
    return this.length > length ? this.slice(0, length) + symbol : this;
};

String.prototype.formatDate = function (locale = 'nl-NL', options = {
    timeStyle: 'short',
    dateStyle: 'short'
}) {
    if (this) {
        const date = new Date(this);
        return new Intl.DateTimeFormat(locale, options).format(date);
    }

    return this;
};

String.prototype.snake = function () {
    return this.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(part => part.toLowerCase())
        .join('_');
};

String.prototype.kebab = function () {
    return this.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(part => part.toLowerCase())
        .join('-');
};

String.prototype.before = function (delimiter) {
    return this.split(delimiter).at(0);
};
