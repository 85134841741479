<template>
    <div id="instance-selector" class="relative cursor-pointer bg-gray-800 hover:bg-gray-900 z-10"
         :class="{ 'bg-gray-900': open }">
        <div class="flex items-center justify-between p-4" @click="toggle">
            <div v-if="currentInstance">
                <span class="block uppercase text-xs text-gray-500 instance-selector-text">
                    {{ $t('conneqt.instances.current_instance') }}
                </span>
                <span class="text-gray-400">{{ currentInstance.name }}</span>
            </div>
            <div v-else>
                <span class="block uppercase text-xs text-gray-500 instance-selector-text">
                    {{ $t('conneqt.instances.select_instance') }}
                </span>
                <span class="text-gray-400 lowercase">
                    {{ instances.length }} {{ $t('conneqt.instances.available') }}
                </span>
            </div>
            <div
                class="flex items-center justify-center w-6 h-6 bg-gray-600 rounded-full text-gray-800 ml-5 instance-selector-dropdown">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-1/2">
                    <path fill="currentColor"
                          d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
                </svg>
            </div>
        </div>
        <div v-show="open"
             class="instance-dropdown absolute left-0 right-0 bg-gray-900 p-2 rounded-b-lg text-sm">
            <div id="instance-input-wrapper">
                <input placeholder="Search instance..." ref="search" type="text" v-model="searchQuery"
                       id="instance-selector-search" @keydown="checkNavigation"
                       class="w-full max-w-full bg-white rounded-sm outline-none p-3 text-sm block"/>

                <div id="sign-out-of-instance" @click.stop="set(null)" v-if="currentInstance">
                    <sign-out/>
                </div>
            </div>

            <div id="instance-list" class="max-h-screen-2/5 overflow-y-scroll">
                <div v-for="(instance, index) in filteredInstances" :key="instance.id"
                     class="p-2 text-gray-500 cursor-pointer rounded instance"
                     :class="instanceClasses(instance, index)" :id="instanceSelector(index)"
                     @click="set(instance)" @mouseover="highlightedItem = index"
                >
                    {{ instance.name }}
                </div>
                <div v-if="filteredInstances.length === 0" class="p-2 text-gray-600 cursor-pointer rounded">
                    No results
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#instance-list {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

#instance-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
</style>

<script>
import { mapMutations, mapState } from 'vuex';
import { cloneDeep } from 'lodash-es';
import SignOut from './common/Icons/SignOut';

export default {
    name:       'InstanceSelector',
    components: { SignOut },
    mounted () {
        this.$store.dispatch('instances/retrieveInstances')
            .then(() => {
                const currentInstance = localStorage.getItem('current_instance');

                if (
                    currentInstance &&
                    this.currentInstance?.id !== parseInt(currentInstance) &&
                    this.filteredInstances.find(instance => instance.id === parseInt(currentInstance))
                ) {
                    this.set(this.filteredInstances.find(instance => instance.id === parseInt(currentInstance)));
                }
            });

        document.addEventListener('click', (event) => {
            // Close the menu if the user clicks outside it
            if (!event.target.closest('#instance-selector')) {
                this.open = false;
            }
        });

        document.addEventListener('keydown', (event) => {
            if (event.metaKey && event.code === 'KeyK') {
                this.open = !this.open;

                if (this.open) {
                    this.blur();

                    // Toggle focus to make sure the input has focus
                    this.$nextTick(() => {
                        this.focus();
                    });
                }

                event.preventDefault();
            }
        });
    },
    data () {
        return {
            open:            false,
            searchQuery:     '',
            highlightedItem: null,
        };
    },
    computed: {
        filteredInstances () {
            let instances = cloneDeep(this.instances);

            if (this.searchQuery.length) {
                instances = instances.filter(instance => instance.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }

            instances = instances
                .sortBy('name', 'asc');

            if (this.searchQuery.length && instances.length >= 0) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.highlightedItem = 0;
            }

            return instances;
        },
        ...mapState({
            instances:       state => state.instances.all,
            currentInstance: state => state.instances.current
        })
    },
    watch:    {
        searchQuery: function () {
            this.resetHighlightedItem();
        },
    },
    methods:  {
        set (instance) {
            if (instance === null) {
                localStorage.removeItem('current_instance');
            } else {
                localStorage.setItem('current_instance', instance.id);
            }

            this.setCurrentInstance(instance);

            this.open = false;
            this.$nextTick(() => {
                window.location.reload();
            });
        },
        toggle () {
            this.open = !this.open;

            if (this.open) {
                this.$nextTick(() => {
                    this.$refs.search.focus();
                });
            }
        },
        ...mapMutations({
            setCurrentInstance: 'instances/setCurrentInstance'
        }),
        checkNavigation (event) {
            if (!this.open) return;
            if (event.code === 'Escape') {
                this.open = false;
                event.preventDefault();
                return;
            }

            let keycode = event.code;

            if (keycode === 'ArrowUp') {
                if (this.highlightedItem > 0) {
                    this.highlightedItem--;
                } else if (this.highlightedItem === 0) {
                    this.highlightedItem = this.filteredInstances.length - 1;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'ArrowDown') {
                if (this.highlightedItem !== null && this.highlightedItem < this.filteredInstances.length - 1) {
                    this.highlightedItem++;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'Enter') {
                if (typeof this.highlightedItem === 'number') {
                    this.set(this.filteredInstances[this.highlightedItem]);
                    this.blur();
                }

                event.preventDefault();
            } else if (keycode === 'Escape') {
                this.blur();

                event.preventDefault();
            }
        },
        checkIfInView () {
            let index = this.highlightedItem || 0; // Contains the item currently in focus

            let element = document.getElementById(this.instanceSelector(index));
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block:    "center",
                    inline:   "start"
                });
            }
        },
        blur () {
            document.querySelector('#instance-selector-search').blur();
        },
        focus () {
            document.querySelector('#instance-selector-search').focus();
        },
        instanceClasses (instance, index) {
            let classes = '';

            if (this.currentInstance && this.currentInstance.id === instance.id) {
                classes = 'current-instance';
            }

            if (this.highlightedItem === index) {
                classes += ' highlighted-instance';
            }

            return classes.trim();
        },
        resetHighlightedItem () {
            this.highlightedItem = 0;
            this.checkIfInView();
        },
        instanceSelector (index) {
            return `conneqt-instance-${index}`;
        },
    },
};
</script>

<style lang="scss" scoped>
#instance-selector {
    .instance-dropdown {
        padding: 0 0.5rem 0.5rem;

        #instance-input-wrapper {
            display: flex;
            gap: 0.25rem;
            width: 100%;
            margin-block: 0 0.5rem;

            #sign-out-of-instance {
                fill: white;
                display: grid;
                place-items: center;
                height: 100%;
                margin-block: auto;
                cursor: pointer;
                transition: all 0.1s linear;
                border-radius: 0.125rem;
                padding: 0.8rem 0.5rem;

                &:hover {
                    fill: #2d3748;
                    background-color: white;
                }

                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }
    }

    .instance {
        transition: all 0.3s ease-in-out;

        &:hover,
        &.highlighted-instance,
        &.current-instance {
            background-color: #e2e8f0;
            color: #2d3748;
        }

        &.current-instance {
            &:before {
                content: '>';
                margin-right: 0.5rem;
            }
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;

        .instance-selector-text {
            font-size: 0.65rem;
        }

        .instance-selector-dropdown {
            margin-left: 0.5rem;
        }
    }
}
</style>
