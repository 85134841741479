import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        items: [],
        current: null
    },
    actions: {
        retrieveLookupTables ({ commit }, { instanceId }) {
            api.data.list(instanceId, 'lookup_table', 1, 1, {}, lookupTables => {
                commit('setAll', lookupTables);
            });
        },
        retrieveLookupTableDetails ({ commit }, { instanceId, tableName, page = 1, pageSize = 10, lookupFilter }) {
            return new Promise((resolve) => {
                const filter = lookupFilter ? JSON.stringify(lookupFilter) : '';

                api.data.get(instanceId, `lookup_table/${tableName}?page=${page}&pageSize=${pageSize}&lookup_filter=${filter}`, result => {
                    commit('setCurrent', result);
                    resolve(result);
                });
            });
        },
        createLookupTable ({ dispatch }, { instanceId, tableName, columns }) {
            return new Promise((resolve) => {
                api.data.post(instanceId, 'lookup_table', { name: tableName, columns }, result => {
                    dispatch('notifications/queueNotification', 'Lookup Table created', { root: true });
                    resolve(result);
                });
            });
        },
        saveLookupTableData ({ commit, dispatch }, { instanceId, tableName, data }) {
            return new Promise((resolve) => {
                api.data.put(instanceId, `lookup_table/${tableName}`, data, result => {
                    dispatch('notifications/queueNotification', 'Lookup Table data saved', { root: true });
                    commit('setCurrent', result);
                    resolve();
                });
            });
        },
        deleteLookupTable ({ dispatch }, { instanceId, tableName }) {
            return new Promise((resolve) => {
                api.data.delete(instanceId, `lookup_table/${tableName}`, () => {
                    dispatch('notifications/queueNotification', 'Lookup Table removed', { root: true });
                    resolve();
                });
            });
        }
    },
    mutations: {
        setAll (state, lookupTables) {
            state.items = lookupTables;
        },
        setCurrent (state, lookupTable) {
            state.current = lookupTable;
        }
    }
};
