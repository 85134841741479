<template>
    <commander-data-provider>
        <slot/>
    </commander-data-provider>
</template>

<script>
import CommanderDataProvider from './CommanderDataProvider';
import { computed, provide, ref, watch } from 'vue';
import { useDataStoreRef } from '../refs/DataStoreRef';
import { useRoute } from 'vue-router';
import api from '../services/api';
import { useStore } from 'vuex';
import { find } from 'lodash-es';

export default {
    name:       "GlobalDataProvider",
    components: { CommanderDataProvider },
    setup () {
        const store = useStore();
        const route = useRoute();

        const sidebarOpen = useDataStoreRef('SidebarOpen', true);
        provide('sidebarOpen', sidebarOpen);

        const filtersOpen = useDataStoreRef('FiltersOpen', true);
        provide('filtersOpen', filtersOpen);

        const breadCrumbs    = ref([]);
        const setBreadcrumbs = (customBreadCrumbs = []) => {
            breadCrumbs.value = [
                ...(route.meta.breadcrumbs || []),
                ...customBreadCrumbs,
            ];
        };
        const addBreadCrumb  = (breadCrumb) => {
            if (breadCrumbs.value.findIndex((crumb) => crumb.name === breadCrumb.name) === -1) {
                breadCrumbs.value.push(breadCrumb);
            }
        };
        watch(() => route, () => {
            setBreadcrumbs();
        }, {
            deep:      true,
            immediate: true,
        });
        provide('setBreadcrumbs', setBreadcrumbs);
        provide('breadCrumbs', breadCrumbs);
        provide('addBreadCrumb', addBreadCrumb);

        const currentInstance = computed(() => store.state.instances.current);

        const features = ref(null);
        const getFeatures = async () => {
            await api.data.get(currentInstance.value.id, 'engine/features', (data) => {
                features.value = data.data;
            }, ((error) => {
                console.log(error.reason || error.message || error);
            }));
        };

        watch(() => currentInstance, () => {
            if (currentInstance.value?.id) getFeatures();
        }, {
            deep:      true,
            immediate: true,
        });

        const featureIsEnabled = (key) => {
            return !!find(features.value || [], (feature) => feature.key === key)?.enabled;
        };

        provide('features', features);
        provide('featureIsEnabled', featureIsEnabled);
    }
};
</script>
