import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        totals: {
            total: 0,
            errors: 0
        },
        chartData: [],
        thisMonthData: [],
        thisMonthDataForAll: [],
        products: []
    },
    actions: {
        retrieveChartData ({ commit }, { instanceId, days, workflowId }) {
            return new Promise((resolve) => {
                api.data.get(instanceId, `statistics/all?days=${days}&workflow=${workflowId}`, (data) => {
                    commit('setChartData', data);
                    resolve();
                });
            });
        },
        retrieveThisMonthData ({ commit }, { instanceId }) {
            return new Promise((resolve) => {
                api.data.get(instanceId, `statistics/month`, (data) => {
                    commit('setThisMonthData', data);
                    resolve();
                });
            });
        },
        retrieveThisMonthDataForAll ({ commit, rootState }) {
            this.state.thisMonthDataForAll = [];
            return new Promise((resolve) => {
                let instances = rootState.instances.all;
                for (let i = 0; i < instances.length; i++) {
                    api.data.get(instances[i].id, `statistics/month`, (response) => {
                        commit('setThisMonthDataForAll', { "id": instances[i].id, "data": response });
                    });
                }

                resolve();
            });
        },
        retrieveProductDetails () {
            return new Promise((resolve) => {
                api.products.list((result) => {
                    resolve(result);
                });
            });
        }
    },
    mutations: {
        setTotals (state, totals) {
            state.totals = totals;
        },
        setChartData (state, chartData) {
            state.chartData = chartData;
        },
        setThisMonthData (state, thisMonthData) {
            state.thisMonthData = thisMonthData;
        },
        setThisMonthDataForAll (state, thisMonthData) {
            state.thisMonthDataForAll.push(thisMonthData);
        }
    }
};