<template>
    <div class="antialiased h-screen font-sans flex flex-col bg-gray-200">
        <div class="flex flex-grow overflow-y-auto">
            <main class="relative flex-grow text-gray-900 overflow-y-auto">
                <notifications/>

                <router-view/>
            </main>
        </div>
    </div>
</template>

<script>
import Notifications from '../components/Notifications';

export default {
    name:       'AuthenticationLayout',
    components: {
        Notifications,
    },
};
</script>
