import api from '../../services/api';
import qs from 'qs';

export default {
    namespaced: true,
    state:      {
        messageStatuses: [],
        items:           [],
        current:         null,
        paging:          {
            total:        0,
            page:         1,
            pageSize:     15,
            totalRecords: 0,
        }
    },
    getters:    {
        messageStatuses (state) {
            var statuses = [];
            Object.keys(state.messageStatuses).forEach(key => {
                statuses.push({
                    id:          state.messageStatuses[key],
                    description: key
                });
            });
            return statuses;
        }
    },
    actions:    {
        retrieveMessages ({ commit }, {
            instanceId,
            page,
            pageSize,
            status,
            handled,
            entityType,
            workflowId,
            adapterId,
            dateFrom,
            dateTo,
            advancedFilters,
            sort
        }) {
            return new Promise((resolve) => {
                let queryPath = 'message';

                let queryParams = {
                    page,
                    pageSize,
                    filter: {
                        status:          status,
                        workflow:        workflowId,
                        adapter:         adapterId,
                        entity_type:     entityType,
                        created_between: `${dateFrom},${dateTo}`,
                        advanced:        advancedFilters.length > 0 ? encodeURI(JSON.stringify(advancedFilters)) : null
                    },
                    sort:   sort
                };

                if (handled) queryParams.filter.handled = handled;

                queryPath += qs.stringify(queryParams, {
                    skipNulls:      true,
                    addQueryPrefix: true,
                    encode:         false
                });

                api.data.get(instanceId, queryPath, (result) => {
                    const meta                                         = result.meta || result;
                    const [total, pageSize, currentPage, totalRecords] = [meta.last_page, meta.per_page, meta.current_page, meta.total];
                    commit('setPaging', {
                        total:        total || 0,
                        pageSize:     pageSize || 0,
                        currentPage:  currentPage || 0,
                        totalRecords: totalRecords || 0,
                    });
                    commit('setAll', result.data || []);
                    resolve();
                });
            });
        },
        retrieveMessageDetails ({
                                    commit,
                                    state
                                }, {
                                    instanceId,
                                    messageGuid,
                                    eventIndex,
                                    refresh,
                                }) {
            if (state.current?.message_guid !== messageGuid) {
                commit('setCurrent', null);
            } else {
                commit('setCurrent', {
                    ...state.current,
                    loading: true,
                });
            }

            return new Promise((resolve, reject) => {
                if (
                    refresh !== true &&
                    state.current?.message_guid === messageGuid &&
                    state.current?.message_data?.events?.[eventIndex]?.data
                ) {
                    return resolve(state.current);
                }

                const uri = typeof eventIndex !== undefined && eventIndex !== null
                    ? `message/${messageGuid}?page=${eventIndex + 1}`
                    : `message/${messageGuid}`;
                api.data.get(instanceId, uri, messageDetails => {
                    if (state.current?.message_guid === messageDetails?.message_guid) {
                        const newMessageDetails                           = structuredClone(state.current);
                        newMessageDetails.message_data.events[eventIndex] = messageDetails.message_data.events[eventIndex];

                        // TODO: when refresh === true, add any new events to the message details and update important message data, like the status

                        messageDetails = newMessageDetails;
                    }

                    messageDetails.loading = false;

                    commit('setCurrent', messageDetails);
                    resolve(messageDetails);
                }, error => reject(error));
            });
        },
        getMessageStatuses ({ commit }, instanceId) {
            api.data.get(instanceId, 'message/status', result => {
                commit('setAvailableMessageStatuses', result);
            });
        },
        retryMessage ({ dispatch }, {
            instanceId,
            messageGuid
        }) {
            messageGuid = Array.isArray(messageGuid) ? messageGuid : [messageGuid];
            api.data.post(instanceId, 'message/retry', { messageGuids: messageGuid }, result => {
                if (Object.keys(result.status).length > 1) {
                    let messageNumber = Object.keys(result.status).length;
                    dispatch('notifications/queueNotification', `${messageNumber} messages retried`, { root: true });
                } else {
                    let retryGuid = Object.keys(result.status)[0];
                    dispatch('notifications/queueNotification', `Message Retried, GUID: ${retryGuid}`, { root: true });
                }
            });
        },
        updateMessage ({
                           dispatch,
                           commit
                       }, {
                           instanceId,
                           messageGuid,
                           data
                       }) {
            return new Promise((resolve) => {
                api.data.put(instanceId, `message/${messageGuid}`, data, result => {
                    commit('setCurrent', result);
                    resolve(result);
                });
            });
        },
    },
    mutations:  {
        setAll (state, instances) {
            state.items = instances;
        },
        setCurrent (state, message) {
            state.current = message;
        },
        setPaging (state, {
            total,
            pageSize,
            currentPage,
            totalRecords
        }) {
            state.paging = {
                total,
                pageSize,
                currentPage,
                totalRecords
            };
        },
        setAvailableMessageStatuses (state, statuses) {
            state.messageStatuses = statuses || {
                "pending":    0,
                "processing": 1,
                "failed":     2,
                "success":    3,
                "canceled":   4
            };
        }
    }
};
