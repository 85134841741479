<template>
    <slot/>

    <teleport to="#teleports">
        <div id="commander" v-if="isLoggedIn">
            <div id="commander-backdrop" @click.stop="commanding = !commanding" v-show="commanding"/>
            <div id="commander-modal" class="bg-gray-800" v-show="commanding">
                <label for="command">
                    Enter command
                </label>
                <input id="command" name="command" placeholder="Start typing..." v-model="commandQuery"
                       class="bg-gray-300"
                       @keydown.enter="selectHighlightedCommand" @keydown.esc="commanding = false" @blur="focus"
                       @keydown.down="decrementHighlightedCommand" @keydown.up="incrementHighlightedCommand"/>

                <ul id="list" class="bg-gray-300">
                    <li class="bg-gray-300"
                        :class="[{highlighted: index === highlightedCommand}, `commander-item-${index}`]"
                        v-for="(value, index) in filteredCommands" @click="selectHighlightedCommand" :key="index"
                        @mouseenter="highlightedCommand = index">
                        <div class="command-data">
                            <span>
                                {{ value?.text }}
                            </span>

                            <span v-if="index + 1 <= 9">
                            ⌘ + {{ index + 1 }}
                        </span>
                        </div>
                        <p v-show="index === highlightedCommand && value.description">
                            {{ value.description }}
                        </p>
                    </li>
                    <li v-if="!filteredCommands?.length">
                        No results found...
                    </li>
                </ul>
                <span class="glyphicon glyphicon-search form-control-feedback"></span>
            </div>
        </div>
    </teleport>
</template>

<script>
import { computed, nextTick, provide, ref, watch } from 'vue';
import callbackRef from '@/refs/callbackRef';
import useCommanderOptions from '@/composables/Commander/CommanderOptions';
import { findIndex } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import useCan from '../composables/Auth/useCan';
import { useStore } from 'vuex';

export default {
    name: "CommanderDataProvider",
    setup () {
        const store  = useStore();
        const router = useRouter();
        const route  = useRoute();

        const isLoggedIn = computed(() => store.state.authentication.isLoggedIn);

        let commanderOptions           = useCommanderOptions(router);
        const commandQuery             = callbackRef(null, () => setupCommands());
        const commanding               = ref(false);
        const filteredCommands         = ref(null);
        const highlightedCommand       = callbackRef(0, () => {
            let element = document.querySelector(`.commander-item-${highlightedCommand.value}`);

            if (!element) {
                element = document.querySelector(`.commander-item-0`);
            }

            if (!element) {
                return;
            }

            element.scrollIntoView({
                behavior: "smooth",
                block:    "nearest",
                inline:   "nearest",
            });
        });
        const selectHighlightedCommand = () => {
            const command = filteredCommands.value?.[highlightedCommand.value];
            command.wildcard ? command.onSelect(commandQuery.value) : command.onSelect();

            commanding.value   = false;
            commandQuery.value = null;
            resetOptions();
        };
        const setupCommands            = () => {
            filteredCommands.value = commanderOptions.filter((option, index) => {
                if (option.wildcard) {
                    const wildcardIndex = findIndex(commanderOptions, c => c.wildcard);

                    return wildcardIndex === index && commandQuery.value?.length;
                }

                return commandQuery.value?.length
                    ? option.text.toLowerCase().includes(commandQuery.value.toLowerCase())
                    : true;
            })
                .filter((option) => !option.permissions ? true : useCan(option.permissions))
                .map(option => {
                    if (!option.wildcard) return option;

                    option.text        = `[WILDCARD] ${commandQuery.value}`;
                    option.description = option.wildcardDescription;

                    return option;
                })
                .sort();

            highlightedCommand.value = 0;
        };
        const addCommandToCommander    = (command) => {
            if (commanderOptions.find((option) => option.text === command.text)) return;

            commanderOptions.push(command);

            setupCommands();
        };

        const resetOptions = () => {
            commanderOptions = useCommanderOptions(router);

            setupCommands();
        };
        const focus        = () => {
            nextTick(() => {
                document.querySelector('#command')?.focus();
            });
        };

        document.addEventListener('keydown', (event) => {
            if (!isLoggedIn.value) return;

            if (!event.altKey && event.metaKey) {
                /**
                 * If the user has entered a combination of the meta key (start/command) and another key, without also
                 * pressing the alt/option key, check the input code and select the requested option if required
                 */
                if (event.code === 'KeyI') {
                    commanding.value = !commanding.value;

                    if (commanding.value) {
                        focus();
                    } else {
                        commandQuery.value = null;
                    }

                    resetOptions();
                    event.preventDefault();
                }

                if (commanding.value && event.code.includes('Digit')) {
                    highlightedCommand.value = event.key - 1;
                    selectHighlightedCommand();

                    resetOptions();
                    event.preventDefault();
                }
            }
        });

        const incrementHighlightedCommand = () => {
            highlightedCommand.value = highlightedCommand.value - 1 < 0
                ? filteredCommands?.value?.length - 1
                : highlightedCommand.value - 1;
        };
        const decrementHighlightedCommand = () => {
            highlightedCommand.value = highlightedCommand.value >= filteredCommands?.value?.length - 1
                ? 0
                : highlightedCommand.value + 1;
        };

        watch(route, () => {
            resetOptions();
        }, {
            immediate: true,
            deep:      true,
        });

        provide('addCommandToCommander', addCommandToCommander);

        return {
            isLoggedIn,
            commanding,
            commandQuery,
            filteredCommands,
            commanderOptions,

            highlightedCommand,
            selectHighlightedCommand,
            focus,

            incrementHighlightedCommand,
            decrementHighlightedCommand,
        };
    },
};
</script>

<style scoped lang="scss">
#commander {
    position: fixed;
    inset: 0 0 0 0;
    pointer-events: none;
    z-index: 2;

    #commander-backdrop {
        position: fixed;
        inset: 0 0 0 0;
        background-color: transparentize(black, 0.3);
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    #commander-modal {
        border-radius: 0.75rem;
        flex-direction: column;
        margin: 10rem auto auto;
        width: 50vw;
        max-width: 50rem;
        position: relative;
        min-height: 8rem;
        max-height: 40vh;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        padding: 1.2rem;
        z-index: 11;

        &, * {
            pointer-events: all;
            font-size: 1.2rem;
        }

        label {
            display: block;
            color: #eee;
            font-size: 1.5rem;
        }

        input {
            padding: 0.5rem 1rem;
            color: black;
            font-size: 1.3rem;
        }

        #list {
            list-style: none;
            margin: 0;
            border-radius: 0 0 5px 5px;
            border: 1px #ccc solid;
            overflow-y: scroll;

            li {
                display: block;
                padding: 5px 15px;

                &:hover, &.highlighted {
                    background-color: #fff;
                }

                .command-data {
                    display: flex;
                    justify-content: space-between;
                }

                span {
                    font-weight: 550;
                }

                p {
                    margin: 5px 0 0;
                }
            }
        }
    }
}
</style>
