<template>
    <div id="sidebar" :class="{'sidebar-hidden': !sidebarOpen}"
         class="relative flex flex-shrink-0 flex-col w-20 sticky top-0 bg-gray-300 z-50">
        <div class="w-full flex flex-grow flex-col items-center px-4">
            <menu-item v-if="can('view dashboard')" :to="{ name: 'dashboard' }" title="Dashboard">
                <svg class="w-full h-25px" role="img" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M288 32C128.94 32 0 160.94 0 320a286.5 286.5 0 0 0 39.06 144.8c5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2A286.5 286.5 0 0 0 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67L305.65 154c-5.13 3.49-11 6-17.64 6a32 32 0 0 1 0-64zM96 384a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm48-160a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm246.77-72.41l-61.33 184A62.8 62.8 0 0 1 343.12 416H232.88a63.34 63.34 0 0 1 51-95.59l61.34-184a24 24 0 0 1 45.53 15.19zm14.66 57.2L421 162.24a31.54 31.54 0 0 1 11-2.24 32 32 0 0 1 0 64c-11.38 0-20.89-6.27-26.57-15.21zM480 384a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M283.9 320.41l61.34-184a24 24 0 0 1 45.53 15.19l-61.33 184a62.8 62.8 0 0 1 13.68 80.4H232.88a63.34 63.34 0 0 1 51-95.59z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('view messages')" :to="{ name: 'messages.index' }" title="Messages">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M224 256.15a32 32 0 0 0-32 32c0 2.8.9 5.2 1.6 7.8l-27.4 27.4L188.8 346l27.4-27.4c2.6.7 5 1.6 7.8 1.6a32 32 0 0 0 0-64zM511.9 303C503.4 139.85 372.2 8.65 209 0a16.08 16.08 0 0 0-17 15.2v32.43a15.85 15.85 0 0 0 15 15.9c129.4 7 233.4 112 240.9 241.5a16 16 0 0 0 15.9 15h32.1a16.12 16.12 0 0 0 16-16.18c.03-.27.02-.55 0-.85zM209.3 96a16.12 16.12 0 0 0-17.25 14.91c0 .4-.05.79-.05 1.19v32.1a15.94 15.94 0 0 0 14.8 15.9c76.8 6.3 138 68.2 144.9 145.2a16.08 16.08 0 0 0 15.9 14.7h32.2a16.19 16.19 0 0 0 16.14-16.25v-1C407.5 192.65 319.4 104.55 209.3 96z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M302.4 487.55c-77.7 41.8-176.7 29.9-242.3-35.7s-77.5-164.5-35.7-242.3c4.9-9.1 17.7-10.3 25-3l256 256c7.4 7.3 6.2 20.1-3 25z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('view mappings')" :to="{ name: 'mappings.index' }" title="Mappings">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M505 359l-80-80c-15-15-41-4.47-41 17v40h-32l-52.78-56.55-53.33 57.14 70.55 75.6a12 12 0 0 0 8.77 3.81H384v40c0 21.46 26 32 41 17l80-80a24 24 0 0 0 0-34zM122.79 96H12a12 12 0 0 0-12 12v56a12 12 0 0 0 12 12h84l52.78 56.55 53.33-57.14-70.55-75.6a12 12 0 0 0-8.77-3.81z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M505 119a24 24 0 0 1 0 34l-80 80c-15 15-41 4.48-41-17v-40h-32L131.56 412.19a12 12 0 0 1-8.77 3.81H12a12 12 0 0 1-12-12v-56a12 12 0 0 1 12-12h84L316.44 99.81a12 12 0 0 1 8.78-3.81H384V56c0-21.44 25.94-32 41-17z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('view workflows')" :to="{ name: 'workflows.index' }" title="Workflows">
                <svg class="w-full h-25px" role="img" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M328 220.33V224c0 32-6.69 47.26-20 63.8-28.2 35-76 39.5-118.2 43.4-25.7 2.4-49.9 4.6-66.1 12.8-3.82 1.94-9.25 6.44-13.44 13.94A80.16 80.16 0 0 0 56 355.67V156.33a80.31 80.31 0 0 0 48 0v144c23.9-11.5 53.1-14.3 81.3-16.9 35.9-3.3 69.8-6.5 85.2-25.7 6.34-7.83 9.5-17.7 9.5-33.7v-3.67a80.31 80.31 0 0 0 48 0z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M80 0a80 80 0 1 0 80 80A80 80 0 0 0 80 0zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16zm0 256a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16zM304 64a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('view mappings')" :to="{ name: 'schedules.index' }" title="Schedules">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item :to="{ name: 'lookup_tables.index' }" title="Lookup Tables">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M288 160v96h160v-96zm0 256h160v-96H288zM64 256h160v-96H64zm0 160h160v-96H64z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M464 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM224 416H64v-96h160zm0-160H64v-96h160zm224 160H288v-96h160zm0-160H288v-96h160z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('manage adapters')" :to="{ name: 'adapters.index' }" title="Adapters">
                <svg class="w-full h-25px" role="img" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M96 0a32 32 0 0 0-32 32v96h64V32A32 32 0 0 0 96 0zm192 0a32 32 0 0 0-32 32v96h64V32a32 32 0 0 0-32-32z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M384 176v32a16 16 0 0 1-16 16h-16v32a160.07 160.07 0 0 1-128 156.8V512h-64v-99.2A160.07 160.07 0 0 1 32 256v-32H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h352a16 16 0 0 1 16 16z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('manage instances') && featureIsEnabled('incidents widget')"
                       :to="{ name: 'incidents.index' }" title="Logbook">
                <svg class="w-full h-25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <g class="fa-group">
                        <path class="fa-primary" sfill="currentColor" opacity="0.4"
                              d="M528 32h-480C21.5 32 0 53.5 0 80v352C0 458.5 21.5 480 48 480h480c26.5 0 48-21.5 48-48v-352C576 53.5 554.5 32 528 32zM512 416H64V96h448V416z"/>
                        <path class="fa-secondary"
                              d="M512 96v320H64V288l118.1-.0313l27.62 55.17c5.875 11.88 22.65 11.86 28.52-.0104L288 243.8L310.1 288H400C408.9 288 416 280.9 416 272S408.9 256 400 256h-70.13l-27.62-55.13c-5.875-11.88-22.62-11.88-28.5 0L224 300.3L204.1 260.4C202.8 257.8 200 256 197 256H64V96H512z"/>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('view settings')" :disabled="true" :to="{ name: 'settings.index' }"
                       title="Settings">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M487.75 315.6l-42.6-24.6a192.62 192.62 0 0 0 0-70.2l42.6-24.6a12.11 12.11 0 0 0 5.5-14 249.2 249.2 0 0 0-54.7-94.6 12 12 0 0 0-14.8-2.3l-42.6 24.6a188.83 188.83 0 0 0-60.8-35.1V25.7A12 12 0 0 0 311 14a251.43 251.43 0 0 0-109.2 0 12 12 0 0 0-9.4 11.7v49.2a194.59 194.59 0 0 0-60.8 35.1L89.05 85.4a11.88 11.88 0 0 0-14.8 2.3 247.66 247.66 0 0 0-54.7 94.6 12 12 0 0 0 5.5 14l42.6 24.6a192.62 192.62 0 0 0 0 70.2l-42.6 24.6a12.08 12.08 0 0 0-5.5 14 249 249 0 0 0 54.7 94.6 12 12 0 0 0 14.8 2.3l42.6-24.6a188.54 188.54 0 0 0 60.8 35.1v49.2a12 12 0 0 0 9.4 11.7 251.43 251.43 0 0 0 109.2 0 12 12 0 0 0 9.4-11.7v-49.2a194.7 194.7 0 0 0 60.8-35.1l42.6 24.6a11.89 11.89 0 0 0 14.8-2.3 247.52 247.52 0 0 0 54.7-94.6 12.36 12.36 0 0 0-5.6-14.1zm-231.4 36.2a95.9 95.9 0 1 1 95.9-95.9 95.89 95.89 0 0 1-95.9 95.9z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary" d="M256.35 319.8a63.9 63.9 0 1 1 63.9-63.9 63.9 63.9 0 0 1-63.9 63.9z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <div class="m-2 h-1 rounded bg-gray-400 w-full"/>
            <menu-item v-if="can('manage instances')" :to="{ name: 'instances.overview' }" title="Instances">
                <svg class="w-full h-25px" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M432 120a24 24 0 1 0-24-24 24 24 0 0 0 24 24zm0 272a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm48-200H32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32v-64a32 32 0 0 0-32-32zm-112 88a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm64 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M456 256a24 24 0 1 0-24 24 24 24 0 0 0 24-24zm24-224H32A32 32 0 0 0 0 64v64a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-112 88a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm64 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm48 232H32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h448a32 32 0 0 0 32-32v-64a32 32 0 0 0-32-32zm-112 88a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm64 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('manage teams')" :to="{ name: 'teams.index' }" title="Teams">
                <svg class="w-full h-25px" role="img" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-secondary"
                              d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
                              fill="currentColor"
                              opacity="0.4"></path>
                        <path class="fa-primary"
                              d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
                              fill="currentColor"></path>
                    </g>
                </svg>
            </menu-item>
            <menu-item v-if="can('manage instances')" :to="{ name: 'admin.index' }" title="Admin" class="mt-auto mb-4">
                <svg class="w-full h-25px" role="img" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                    <g class="fa-group">
                        <path class="fa-primary"
                              d="M373.4 318.9l-23.21-13.4C351.3 299.8 352 293.1 352 288s-.7129-11.78-1.76-17.46l23.21-13.4c3.213-1.852 4.809-5.695 3.689-9.234C374.6 239.7 371.3 231.7 366.9 224s-9.781-14.55-15.56-20.86c-2.506-2.738-6.633-3.281-9.846-1.422l-23.46 13.54C309.2 207.7 299.1 201.8 288 197.9V170.9c0-3.711-2.531-7.016-6.154-7.816C273.5 161.2 264.9 160 256 160C247.1 160 238.5 161.2 230.2 163C226.5 163.8 224 167.1 224 170.9v27.02C212.9 201.8 202.8 207.7 194 215.3L170.6 201.7C167.3 199.9 163.2 200.4 160.7 203.1C154.9 209.4 149.6 216.3 145.1 224C140.7 231.7 137.4 239.7 134.9 247.9C133.7 251.4 135.3 255.3 138.6 257.1l23.21 13.4C160.7 276.2 160 282 160 288s.7129 11.78 1.76 17.46L138.6 318.9c-3.215 1.852-4.809 5.695-3.691 9.234C137.4 336.3 140.7 344.3 145.1 352c4.457 7.719 9.783 14.55 15.56 20.86c2.508 2.738 6.633 3.281 9.848 1.422l23.45-13.54C202.8 368.3 212.9 374.2 224 378.1v27.02c0 3.711 2.531 7.016 6.156 7.812C238.5 414.8 247.1 416 256 416c8.914 0 17.49-1.195 25.85-3.047C285.5 412.2 288 408.9 288 405.1v-27.02c11.12-3.957 21.19-9.867 29.99-17.38l23.46 13.54c3.213 1.859 7.34 1.316 9.846-1.422C357.1 366.6 362.4 359.7 366.9 352s7.711-15.75 10.29-23.91C378.3 324.6 376.7 320.7 373.4 318.9zM256 328c-22.09 0-40-17.91-40-40S233.9 248 256 248S296 265.9 296 288S278.1 328 256 328z"/>
                        <path class="fa-secondary" opacity="0.4"
                              d="M464 96h-192l-64-64h-160C21.5 32 0 53.5 0 80v352C0 458.5 21.5 480 48 480h416c26.5 0 48-21.5 48-48v-288C512 117.5 490.5 96 464 96zM366.9 352c-4.457 7.719-9.781 14.55-15.56 20.86c-2.506 2.738-6.633 3.281-9.846 1.422l-23.46-13.54C309.2 368.3 299.1 374.2 288 378.1v27.02c0 3.711-2.531 7.016-6.154 7.812C273.5 414.8 264.9 416 256 416c-8.912 0-17.49-1.195-25.84-3.047C226.5 412.2 224 408.9 224 405.1v-27.02c-11.12-3.957-21.19-9.867-29.99-17.38L170.6 374.3c-3.215 1.859-7.34 1.316-9.848-1.422C154.9 366.6 149.6 359.7 145.1 352c-4.455-7.719-7.709-15.75-10.29-23.91C133.7 324.6 135.3 320.7 138.6 318.9l23.21-13.4C160.7 299.8 160 293.1 160 288s.7129-11.78 1.76-17.46L138.6 257.1C135.3 255.3 133.7 251.4 134.9 247.9C137.4 239.7 140.7 231.7 145.1 224c4.457-7.719 9.783-14.55 15.56-20.86C163.2 200.4 167.3 199.9 170.6 201.7l23.45 13.54C202.8 207.7 212.9 201.8 224 197.9V170.9c0-3.711 2.531-7.016 6.156-7.816C238.5 161.2 247.1 160 256 160c8.914 0 17.49 1.195 25.85 3.043C285.5 163.8 288 167.1 288 170.9v27.02c11.12 3.957 21.19 9.867 29.99 17.38l23.46-13.54c3.213-1.859 7.34-1.316 9.846 1.422C357.1 209.4 362.4 216.3 366.9 224s7.711 15.75 10.29 23.91c1.119 3.539-.4766 7.383-3.689 9.234l-23.21 13.4C351.3 276.2 352 282 352 288s-.7129 11.78-1.76 17.46l23.21 13.4c3.213 1.852 4.809 5.695 3.691 9.234C374.6 336.3 371.3 344.3 366.9 352zM256 248C233.9 248 216 265.9 216 288s17.91 40 40 40S296 310.1 296 288S278.1 248 256 248z"/>
                    </g>
                </svg>
            </menu-item>
        </div>
    </div>
</template>

<script>
import MenuItem from './MenuItem';
import useCan from "../composables/Auth/useCan";
import { inject } from 'vue';

export default {
    name:       "Sidebar",
    components: { MenuItem },
    setup () {
        const sidebarOpen      = inject('sidebarOpen');
        const featureIsEnabled = inject('featureIsEnabled');

        return {
            can: useCan,

            sidebarOpen,
            featureIsEnabled,
        };
    }
};
</script>

<style lang="scss" scoped>
#sidebar {
    transition: all 300ms ease-in-out;

    &.sidebar-hidden {
        transform: translateX(-100vw);
        width: 0;
    }
}
</style>
