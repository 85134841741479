import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        items: [],
        current: null,
        errors: null
    },
    actions: {
        retrieveAdapters ({ commit }, { instanceId, services }) {
            const additional = {};
            if (services) additional.services = services;

            api.data.list(instanceId, 'adapter', 1, 1, additional, result => {
                commit('setItems', result);
            });
        },
        retrieveAdapterDetails ({ commit }, { instanceId, adapterId }) {
            return new Promise((resolve) => {
                const otherServices = ['engine'];
                if (!otherServices.includes(adapterId)) {
                    api.data.get(instanceId, `adapter/${adapterId}`, result => {
                        commit('setCurrent', result);
                        resolve(result);
                    });
                } else {
                    const result = {
                        id:             adapterId,
                        identifier:     adapterId.ucfirst(),
                        not_an_adapter: true,
                    };

                    commit('setCurrent', result);
                    resolve(result);
                }
            });
        },
        retrieveAdapterSettings (context, { instanceId, adapterId }) {
            return new Promise((resolve) => {
                const otherServices = ['engine'];
                if (!otherServices.includes(adapterId)) {
                    api.data.get(instanceId, `adapter/${adapterId}/settings`, result => {
                        resolve(result);
                    });
                } else {
                    switch (adapterId) {
                        case 'engine':
                            api.data.get(instanceId, `engine/settings`, result => {
                                resolve(result);
                            });
                            break;
                        default:
                            throw new Error('Unknown adapter');
                    }
                }
            });
        },
        updateAdapterSettings (context, { instanceId, adapterId, settings }) {
            return new Promise((resolve) => {
                const otherServices = ['engine'];
                if (!otherServices.includes(adapterId)) {
                    api.data.put(instanceId, `adapter/${adapterId}/settings`, { settings }, result => {
                        resolve(result);
                    });
                } else {
                    switch (adapterId) {
                        case 'engine':
                            api.data.put(instanceId, `engine/settings`, { settings }, result => {
                                resolve(result);
                            });
                            break;
                        default:
                            throw new Error('Unknown adapter');
                    }
                }
            });
        },
        createAdapter ({ dispatch, commit }, { instanceId, adapter }) {
            return new Promise((resolve) => {
                api.data.post(instanceId, `adapter`, adapter, result => {
                    dispatch('notifications/queueNotification', 'Successfully registered adapter', { root: true });
                    resolve(result);
                }, (error) => {
                    commit('setErrors', error.response.data.errors);
                });
            });
        },
        updateAdapter (context, { instanceId, adapter }) {
            return new Promise((resolve) => {
                api.data.put(instanceId, `adapter/${adapter.id}`, adapter, result => {
                    resolve(result);
                });
            });
        },
    },
    mutations: {
        setItems (state, adapters) {
            state.items = adapters;
        },
        setCurrent (state, adapter) {
            state.current = adapter;
        },
        setErrors (state, errors) {
            state.errors = errors;
        }
    }
};
