import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        items: [],
        current: null
    },
    actions: {
        retrieveMappings ({ commit }, instanceId) {
            api.data.list(instanceId, 'mapping', 1, 1, {}, result => {
                commit('setAll', result);
            });
        },
        retrieveMappingDetails ({ commit }, { instanceId, mappingId }) {
            return new Promise((resolve) => {
                api.data.get(instanceId, `mapping/${mappingId}`, result => {
                    commit('setCurrent', result);
                    resolve();
                });
            });
        },
        createMapping ({ dispatch }, { instanceId, mapping }) {
            return new Promise((resolve) => {
                api.data.post(instanceId, `mapping`, mapping, result => {
                    dispatch('notifications/queueNotification', 'Successfully created mapping', { root: true });
                    resolve(result);
                });
            });
        },
        updateMapping ({ dispatch }, { instanceId, mapping, definition }) {
            return new Promise((resolve) => {
                api.data.put(instanceId, `mapping/${mapping.id}`, { definition }, result => {
                    resolve(result);
                    dispatch('notifications/successNotification', 'Successfully updated mapping', { root: true });
                });
            });
        },
        deleteMapping ({ dispatch }, { instanceId, mappingId }) {
            return new Promise((resolve) => {
                api.data.delete(instanceId, `mapping/${mappingId}`, () => {
                    dispatch('notifications/queueNotification', 'Successfully deleted mapping', { root: true });
                    resolve();
                });
            });
        },
        testMapping (context, { instanceId, workflowId, sampleData, mappingDefinition }) {
            return new Promise((resolve) => {
                api.data.post(instanceId, 'engine/test-mapping', {
                    workflow_id: workflowId,
                    data: sampleData,
                    mapping: mappingDefinition
                }, result => {
                    resolve(result);
                });
            });
        }
    },
    mutations: {
        setAll (state, mappings) {
            state.items = mappings;
        },
        setCurrent (state, mapping) {
            state.current = mapping;
        }
    }
};
