import { createI18n } from 'vue-i18n';
import English from './Languages/English';
import Dutch from './Languages/Dutch';

const i18n = createI18n({
    globalInjection: true,
    legacy:          false,
    locale:          'en',
    fallbackLocale:  'en',
    messages:        {
        en: English,
        nl: Dutch,
    },
});

export default i18n;
