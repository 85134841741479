<template>
    <div class="version-modal" v-if="hasNewVersion">
        <h4>
            <template v-if="!isBeta">
                {{ latestVersion.tag }} - A new version is available.
            </template>
            <template v-else>
                {{ latestVersion.tag }} - A new
                <mark class="beta-change">BETA</mark>
                version is available.
            </template>
        </h4>
        <hr class="my-2">
        <div class="text-sm">
            <p v-html="latestVersion.description"></p>

            <template v-if="latestVersion.changelog">
                <div class="font-medium text-base text-ferlin-blue cursor-pointer" @click="readMore = !readMore">
                    {{ !readMore ? $t('general.read_more') : $t('general.read_less') }}
                </div>

                <ul class="mt-2 list-decimal pl-6 pr-4" v-if="readMore">
                    <li class="mb-2" v-for="(change, index) in latestVersion.changelog" :key="index"
                        v-html="parseChangelogItem(change)"/>
                </ul>
            </template>
        </div>
        <div class="flex flex-row flex-wrap justify-between">
            <div class="release-date text-sm">
                {{ $t('general.release') }}: {{
                    (new Date(latestVersion.created_at)).format('DD-M-YYYY | HH:mm').replace('|', ' ' + $t('general.at') + ' ')
                }}
            </div>
            <button class="ml-auto secondary-button" @click="reload">
                {{ $t('general.refresh') }}
            </button>
        </div>
    </div>
</template>

<script>
import { filter } from 'lodash-es';
import { mapState } from 'vuex';
import api from '../../services/api';

export default {
    name: 'VersionChecker',
    data () {
        return {
            readMore:      false,
            hasNewVersion: false,
            latestVersion: null,
            timeout:       null,
        };
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.authentication.isLoggedIn,
        }),
        isBeta () {
            return !!filter(this.latestVersion?.changelog, (change) => change.includes('[BETA]')).length;
        },
    },
    methods:  {
        parseChangelogItem (text) {
            return text
                .replace('[BETA]', '<mark class="beta-change">BETA</mark>');
        },
        reload () {
            localStorage.setItem('version', this.latestVersion.id);
            this.hasNewVersion = false;

            document.location.reload();
        },
        async getLatest () {
            this.cancelTimeout();

            if (!this.isLoggedIn) {
                this.timeout = setTimeout(() => {
                    this.getLatest();
                }, 60 * 1000 * 2.5);
                return;
            }

            try {
                const { data } = await api.get('settings/versions?per_page=1');

                const latestVersion  = data.data;
                const currentVersion = localStorage.getItem('version');

                if (currentVersion && latestVersion.id > currentVersion) {
                    this.hasNewVersion = true;
                } else if (!currentVersion) {
                    localStorage.setItem('version', latestVersion.id);
                }

                this.latestVersion = latestVersion;
            } catch (error) {
                console.dir(error);
            } finally {
                this.timeout = setTimeout(() => {
                    this.getLatest();
                }, 60 * 1000 * 2.5);
            }
        },
        cancelTimeout () {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    },
    mounted () {
        this.getLatest();
    },
    beforeUnmount () {
        this.cancelTimeout();
    },
};
</script>

<style lang="scss">
.version-modal {
    position: fixed;
    width: 450px;
    max-width: 90vw;
    padding: 10px;
    background-color: #fff;
    right: 15px;
    bottom: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .75);
    border-radius: 5px;
    z-index: 9999;

    h4 {
        @apply text-lg;
        @apply font-bold;
    }

    .release-date {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
    }
}

.beta-change {
    background-color: red;
    color: white;
    border: 1px solid #dedede;
    padding: 1px 5px;
    border-radius: 5px;
    font-weight: 500;
    width: max-content;
}
</style>
