<template>
    <div class="instance-quick-selection">
        <h1 class="text-xl mb-2 block">
            Quick connections:
        </h1>

        <div class="action-row">
            <a class="action" :href="`ssh://forge@${instance.ip_address}`">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path class="fa-primary" fill="currentColor" opacity="0.4"
                          d="M71.78 185.7C62.01 176.7 61.35 161.6 70.31 151.8C79.26 142 94.45 141.4 104.2 150.3L200.2 238.3C205.2 242.9 208 249.3 208 256C208 262.7 205.2 269.1 200.2 273.7L104.2 361.7C94.45 370.6 79.27 369.1 70.31 360.2C61.35 350.4 62.01 335.3 71.78 326.3L148.5 256L71.78 185.7zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H216C202.7 384 192 373.3 192 360C192 346.7 202.7 336 216 336H360z"/>
                    <path class="fa-secondary" fill="currentColor"
                          d="M0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM70.31 151.8C61.35 161.6 62.01 176.7 71.78 185.7L148.5 256L71.78 326.3C62.01 335.3 61.35 350.4 70.31 360.2C79.26 369.1 94.45 370.6 104.2 361.7L200.2 273.7C205.2 269.1 208 262.7 208 255.1C208 249.3 205.2 242.9 200.2 238.3L104.2 150.3C94.45 141.4 79.26 142 70.31 151.8V151.8zM216 336C202.7 336 192 346.7 192 360C192 373.3 202.7 384 216 384H360C373.3 384 384 373.3 384 360C384 346.7 373.3 336 360 336H216z"/>
                </svg>
                SSH
            </a>
            <a class="action"
               :href="`mysql+ssh://forge@${instance.ip_address}/forge@127.0.0.1?name=${instance.name}&usePrivateKey=true`">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path class="fa-primary" fill="currentColor" opacity="0.4"
                          d="M0 186.1V128C0 172.2 100.3 208 224 208C347.7 208 448 172.2 448 128V186.1C433.1 197.8 413.1 207.3 393.2 214.7C348.3 230.7 288.5 240 224 240C159.5 240 99.66 230.7 54.85 214.7C34.02 207.3 14.93 197.8 0 186.1V186.1zM0 346.1V288C0 332.2 100.3 368 224 368C347.7 368 448 332.2 448 288V346.1C433.1 357.8 413.1 367.3 393.2 374.7C348.3 390.7 288.5 400 224 400C159.5 400 99.66 390.7 54.85 374.7C34.02 367.3 14.93 357.8 0 346.1z"/>
                    <path class="fa-secondary" fill="currentColor"
                          d="M448 80V128C448 172.2 347.7 208 224 208C100.3 208 0 172.2 0 128V80C0 35.82 100.3 0 224 0C347.7 0 448 35.82 448 80zM393.2 214.7C413.1 207.3 433.1 197.8 448 186.1V288C448 332.2 347.7 368 224 368C100.3 368 0 332.2 0 288V186.1C14.93 197.8 34.02 207.3 54.85 214.7C99.66 230.7 159.5 240 224 240C288.5 240 348.3 230.7 393.2 214.7V214.7zM54.85 374.7C99.66 390.7 159.5 400 224 400C288.5 400 348.3 390.7 393.2 374.7C413.1 367.3 433.1 357.8 448 346.1V432C448 476.2 347.7 512 224 512C100.3 512 0 476.2 0 432V346.1C14.93 357.8 34.02 367.3 54.85 374.7z"/>
                </svg>
                Database
            </a>
            <a class="action" target="_blank"
               :href="`http://${instance.ip_address}:10000/horizon/dashboard`">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M504.4,115.83a5.72,5.72,0,0,0-.28-.68,8.52,8.52,0,0,0-.53-1.25,6,6,0,0,0-.54-.71,9.36,9.36,0,0,0-.72-.94c-.23-.22-.52-.4-.77-.6a8.84,8.84,0,0,0-.9-.68L404.4,55.55a8,8,0,0,0-8,0L300.12,111h0a8.07,8.07,0,0,0-.88.69,7.68,7.68,0,0,0-.78.6,8.23,8.23,0,0,0-.72.93c-.17.24-.39.45-.54.71a9.7,9.7,0,0,0-.52,1.25c-.08.23-.21.44-.28.68a8.08,8.08,0,0,0-.28,2.08V223.18l-80.22,46.19V63.44a7.8,7.8,0,0,0-.28-2.09c-.06-.24-.2-.45-.28-.68a8.35,8.35,0,0,0-.52-1.24c-.14-.26-.37-.47-.54-.72a9.36,9.36,0,0,0-.72-.94,9.46,9.46,0,0,0-.78-.6,9.8,9.8,0,0,0-.88-.68h0L115.61,1.07a8,8,0,0,0-8,0L11.34,56.49h0a6.52,6.52,0,0,0-.88.69,7.81,7.81,0,0,0-.79.6,8.15,8.15,0,0,0-.71.93c-.18.25-.4.46-.55.72a7.88,7.88,0,0,0-.51,1.24,6.46,6.46,0,0,0-.29.67,8.18,8.18,0,0,0-.28,2.1v329.7a8,8,0,0,0,4,6.95l192.5,110.84a8.83,8.83,0,0,0,1.33.54c.21.08.41.2.63.26a7.92,7.92,0,0,0,4.1,0c.2-.05.37-.16.55-.22a8.6,8.6,0,0,0,1.4-.58L404.4,400.09a8,8,0,0,0,4-6.95V287.88l92.24-53.11a8,8,0,0,0,4-7V117.92A8.63,8.63,0,0,0,504.4,115.83ZM111.6,17.28h0l80.19,46.15-80.2,46.18L31.41,63.44Zm88.25,60V278.6l-46.53,26.79-33.69,19.4V123.5l46.53-26.79Zm0,412.78L23.37,388.5V77.32L57.06,96.7l46.52,26.8V338.68a6.94,6.94,0,0,0,.12.9,8,8,0,0,0,.16,1.18h0a5.92,5.92,0,0,0,.38.9,6.38,6.38,0,0,0,.42,1v0a8.54,8.54,0,0,0,.6.78,7.62,7.62,0,0,0,.66.84l0,0c.23.22.52.38.77.58a8.93,8.93,0,0,0,.86.66l0,0,0,0,92.19,52.18Zm8-106.17-80.06-45.32,84.09-48.41,92.26-53.11,80.13,46.13-58.8,33.56Zm184.52,4.57L215.88,490.11V397.8L346.6,323.2l45.77-26.15Zm0-119.13L358.68,250l-46.53-26.79V131.79l33.69,19.4L392.37,178Zm8-105.28-80.2-46.17,80.2-46.16,80.18,46.15Zm8,105.28V178L455,151.19l33.68-19.4v91.39h0Z"/>
                </svg>

                Horizon
            </a>
            <a class="action" target="_blank"
               :href="`https://forge.laravel.com/servers/${instance.forge_server_id}`">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path class="fa-primary" fill="currentColor" opacity="0.4"
                          d="M480 32H32C14.38 32 0 46.38 0 64v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32V64C512 46.38 497.6 32 480 32zM352 152c-13.25 0-24-10.75-24-24S338.8 104 352 104S376 114.8 376 128S365.3 152 352 152zM416 152c-13.25 0-24-10.75-24-24S402.8 104 416 104S440 114.8 440 128S429.3 152 416 152zM416 360c-13.25 0-24 10.75-24 24s10.75 24 24 24c13.26 0 24-10.75 24-24S429.3 360 416 360z"/>
                    <path class="fa-secondary" fill="currentColor"
                          d="M480 288H32c-17.62 0-32 14.38-32 32v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32v-128C512 302.4 497.6 288 480 288zM352 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S365.3 408 352 408zM416 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S429.3 408 416 408zM416 152c13.25 0 24-10.75 24-24S429.3 104 416 104S392 114.8 392 128S402.8 152 416 152z"/>
                </svg>

                Forge
            </a>
            <a class="action" target="_blank"
               :href="`https://console.hetzner.cloud/projects/138312/servers/${instance.hetzner_id}/overview`">
                <cloud/>

                Hetzner
            </a>
        </div>
    </div>
</template>

<script>
import Cloud from "@/components/common/Icons/Cloud";

export default {
    name:  "InstanceQuickConnections",
    components: {Cloud},
    props: {
        instance: {
            required: true,
            type:     Object,
        },
    },
};
</script>

<style lang="scss" scoped>
.instance-quick-selection {
    .action-row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.5rem;
        max-width: 50rem;

        .action {
            padding: 0.5rem 1rem;
            border: 1px solid #2D3748;
            border-radius: 0.375rem;
            background-color: #2d3748;
            color: white;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            font-size: 1rem;

            &:hover {
                color: rgba(45, 55, 72, 1);
                background-color: transparent;

                svg {
                    fill: unset;
                }
            }

            svg {
                fill: white;
                height: 1.2rem;
                margin-right: 0.5rem;
            }
        }
    }
}
</style>
