import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';
import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes: routes,
});

router.beforeResolve((to, from, next) => {
    store.commit('navigation/setNavigationTitle', null);
    store.commit('navigation/updateNavigationStack', to.matched);
    next();
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => !!route.meta.secure) && !store.state.authentication.isLoggedIn) {
        return next({ name: 'login' });
    }

    if (to.matched.some(route => route.meta.secure === false) && store.state.authentication.isLoggedIn) {
        return next({ name: 'dashboard' });
    }

    next();
});

export default router;
