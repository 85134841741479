<template>
    <global-data-provider>
        <div class="antialiased h-screen font-sans flex flex-col bg-gray-200">
            <top-bar/>
            <notifications/>

            <div class="flex flex-grow overflow-y-auto">
                <main class="relative flex-grow text-gray-900 overflow-y-auto">
                    <dashboard-instance-selector/>
                </main>

                <version-checker/>
            </div>
        </div>
    </global-data-provider>
</template>

<script>
import {useStore}                from 'vuex';
import TopBar                    from '../components/TopBar';
import Notifications             from '../components/Notifications';
import VersionChecker            from '../components/common/VersionChecker';
import DashboardInstanceSelector from '../components/DashboardInstanceSelector';
import {
    computed,
    provide,
    watch
}                                from 'vue';
import useCan                    from "@/composables/Auth/useCan";
import GlobalDataProvider        from "@/DataProviders/GlobalDataProvider";

export default {
    name:       "InstanceSelectionLayout",
    components: {
        GlobalDataProvider,
        DashboardInstanceSelector,
        VersionChecker,
        Notifications,
        TopBar,
    },
    setup() {
        const store           = useStore();
        const currentInstance = computed(() => store.state.instances.current);
        const user            = computed(() => store.state.authentication.user);

        watch(() => currentInstance, () => {
            if (currentInstance.value?.id) {
                store.dispatch('messages/getMessageStatuses', currentInstance.value.id);
                store.dispatch('engine/getEntityTypes', {instanceId: currentInstance.value.id});
            }
        }, {
            deep:      true,
            immediate: true,
        });

        provide('currentInstance', currentInstance);
        provide('user', user);

        return {
            currentInstance,
            user,

            can: useCan,
        };
    },
};
</script>
