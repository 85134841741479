import axios from 'axios';
import store from '../store';

const apiUrl = process.env.VUE_APP_API_BASE_URL;
// Store the access token in local storage
const storeAccessToken = (token) => {
    localStorage.setItem('access_token', token);
};

// Retrieve the access token from local storage
const getAccessToken = () => {
    return localStorage.getItem('access_token');
};

// Clear the access token from local storage
const clearAccessToken = () => {
    localStorage.removeItem('access_token');
};

// Create an Axios instance with the authentication header set
const client = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Authorization: `Bearer ${getAccessToken()}`
    }
});

client.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return config;
}, error => {
    return Promise.reject(error);
});

// Interceptor for refreshing the access token
client.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const {
              config,
              response: {status}
          } = error;
    const refreshToken = localStorage.getItem('refresh_token');
    if (status === 401 && config.url !== `${apiUrl}/oauth/token` && refreshToken) {
        // Refresh the access token
        try {
            const {data}       = await axios.post(`${apiUrl}/oauth/token`, {
                grant_type:    'refresh_token',
                refresh_token: refreshToken,
                client_id:     process.env.VUE_APP_PASSPORT_CLIENT_ID,
                client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
            });

            // Store the new access and refresh tokens
            storeAccessToken(data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            // Update the header with the new access token
            client.defaults.headers.Authorization = `Bearer ${data.access_token}`;

            // Resend the original request
            return client(config);
        } catch (e) {
            // If the refresh token is invalid, log out the user
            clearAccessToken();
            await store.dispatch('authentication/logout');
            throw e;
        }
    }
    throw error;
});

client.retrieveAccessToken = (email, password, callback, errorCallback) => {
    client.post('/oauth/token', {
        grant_type:    'password',
        client_id:     process.env.VUE_APP_PASSPORT_CLIENT_ID,
        client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
        username:      email,
        password:      password,
    }, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
        .then(({data}) => {
            storeAccessToken(data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('expires_in', data.expires_in);

            callback(data);
        })
        .catch((error) => errorCallback(error));
};

export default client;
