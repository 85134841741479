export default {
    actions: {
        cancel:       'Sluit',
        confirm:      'Bevestig',
        save_changes: 'Opslaan',
        logout:       'Uitloggen',
        export:       'Exporteer',
    },

    datepicker: {
        today:  'Vandaag',
        select: 'Selecteer',
        cancel: 'Sluit',
    },

    dates: {
        filter_options: {
            today:        'vandaag',
            last_week:    'afgelopen week',
            last_30_days: 'afgelopen 30 dagen',
            last_year:    'afgelopen year',
        },
    },

    all:       'all',
    for:       'voor de',
    release:   'Release',
    at:        'om',
    read_more: 'Lees meer',
    read_less: 'Verberg',
    refresh:   'Verversen',
    unknown:   'Onbekend',
};
