<template>
    <div class="menu-item text-gray-700" :class="{ 'opacity-25': disabled, 'pointer-events-none': disabled }">
        <router-link
            :to="to" @click="onLinkClick"
            class="group relative flex justify-center items-center h-12 my-2 rounded hover:bg-gray-400 hover:text-gray-700"
            active-class="bg-gray-400 text-gray-700"
        >
            <slot/>
            <span style="transition: all .2s ease-in-out"
                  class="pointer-events-none whitespace-no-wrap text-sm opacity-0 bg-gray-600 text-white p-2 rounded absolute left-full ml-0 z-50 group-hover:opacity-90 group-hover:ml-3">
                {{ title }}
            </span>
        </router-link>
    </div>
</template>

<script>
    import { inject } from 'vue';

    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            to: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        setup() {
            const sidebarOpen = inject('sidebarOpen');
            const onLinkClick = () => {
                if (window.screen.width <= 800) {
                    sidebarOpen.value = false;
                }
            };

            return {
                onLinkClick,
            };
        }
    };
</script>

<style lang="scss" scoped>
.menu-item {
    width: 3rem;
}
</style>
