import general from './general';
import conneqt from './conneqt';
import settings from './settings';

export default {
    pages: {
        settings: {
            title: 'Settings',
        },
    },

    general,
    conneqt,
    settings,
};
