<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path class="fa-secondary" fill="currentColor"
              d="M96.2 200.1C96.07 197.4 96 194.7 96 192C96 103.6 167.6 32 256 32C315.3 32 367 64.25 394.7 112.2C409.9 101.1 428.3 96 448 96C501 96 544 138.1 544 192C544 204.2 541.7 215.8 537.6 226.6C596 238.4 640 290.1 640 352C640 422.7 582.7 480 512 480H144C64.47 480 0 415.5 0 336C0 273.2 40.17 219.8 96.2 200.1z"/>
    </svg>
</template>

<script>
export default {
    name: "Cloud"
}
</script>
