export default {
    actions: {
        cancel:       'Cancel',
        confirm:      'Confirm',
        save_changes: 'Save Changes',
        logout:       'Logout',
        export:       'Export',
    },

    datepicker: {
        today:  'Today',
        select: 'Select',
        cancel: 'Cancel',
    },

    dates: {
        filter_options: {
            today:        'today',
            last_week:    'the last week',
            last_30_days: 'the last 30 days',
            last_year:    'the last year',
        },
    },

    all:       'alle',
    for:       'for',
    release:   'Release',
    at:        'at',
    read_more: 'Read more',
    read_less: 'Hide',
    refresh:   'Refresh',
    unknown:   'Unknown',
};
