import api from '../../services/api';

export default {
    namespaced: true,
    actions: {
        retrieveProvisioningEvents (context, { page, pageSize }) {
            return new Promise((resolve) => {
                api.provisioning.getEvents(page, pageSize, events => {
                    resolve(events.data);
                });
            });
        },
        retrieveProvisioningRunsForInstance (context, instanceId) {
            return new Promise((resolve) => {
                api.provisioning.getRunsForInstance(instanceId, runs => {
                    resolve(runs.data);
                });
            });
        },
        retrieveDaemonsForInstance (context, instanceId) {
            return new Promise((resolve) => {
                api.provisioning.getDaemonsForInstance(instanceId, daemons => {
                    resolve(daemons);
                });
            });
        },
        createDaemon (context, { instanceId, daemonSettings }) {
            return new Promise((resolve) => {
                api.provisioning.createDaemonForInstance(instanceId, daemonSettings, () => {
                    resolve();
                });
            });
        }
    }
};
