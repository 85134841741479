<template>
    <section id="dashboard-instance-selector">
        <div id="dashboard-instance-selector-title">
            {{ $t('conneqt.instances.please_select_instance') }}
        </div>

        <input placeholder="Search instance..." ref="search" type="text" v-model="searchQuery"
               id="dashboard-instance-selector-search" @blur="focus" @keydown="checkNavigation"
               class="w-full max-w-full -mt-2 mb-2 bg-white rounded-sm outline-none p-3 text-sm block"/>

        <ul id="instance-list">
            <li class="instance" @click="setInstance(instance)" v-for="(instance, index) in filteredInstances"
                :key="instance.id" :class="instanceClasses(instance, index)" :id="instanceSelector(index)"
                @mouseover="highlightedItem = index">
                {{ instance.name }}
            </li>
        </ul>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { cloneDeep }              from 'lodash-es';
import useCan                     from "@/composables/Auth/useCan";

export default {
    name: "DashboardInstanceSelector",
    data () {
        return {
            open:            false,
            searchQuery:     '',
            highlightedItem: null,

            can: useCan,
        };
    },
    inject: ['user'],
    computed: {
        filteredInstances () {
            let instances = cloneDeep(this.instances);

            if (this.searchQuery.length > 0) {
                instances = instances.filter(instance => instance.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }

            return instances
                .sortBy('name', 'asc');
        },
        ...mapState({
            instances:       state => state.instances.all,
            currentInstance: state => state.instances.current
        })
    },
    watch:    {
        searchQuery: function () {
            this.resetHighlightedItem();
        },
    },
    methods:  {
        setInstance (instance) {
            let shouldRefresh = this.currentInstance !== null && instance.id !== this.currentInstance.id;

            localStorage.setItem('current_instance', instance.id);
            this.setCurrentInstance(instance);

            if (shouldRefresh) {
                this.$nextTick(() => {
                    window.location.reload();
                });
            }
        },
        ...mapMutations({
            setCurrentInstance: 'instances/setCurrentInstance',
        }),
        checkNavigation (event) {
            if (document.activeElement?.id !== 'dashboard-instance-selector-search') return;

            let keycode = event.code;

            const screenWidth = window.screen.width;
            const isMobile    = screenWidth <= 800;
            const isTablet    = screenWidth >= 800 && screenWidth <= 1200;
            const isDesktop   = screenWidth > 1200;

            if (keycode === 'ArrowUp') {
                let amountToMove = 4;
                if (isTablet) {
                    amountToMove = 2;
                } else if (isMobile) {
                    amountToMove = 1;
                }

                if (this.highlightedItem - amountToMove >= 0) {
                    this.highlightedItem -= amountToMove;
                } else if (this.highlightedItem - amountToMove === 0) {
                    this.highlightedItem = this.filteredInstances.length - amountToMove - 1;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'ArrowDown') {
                let amountToMove = 4;
                if (isTablet) {
                    amountToMove = 2;
                } else if (isMobile) {
                    amountToMove = 1;
                }

                if (this.highlightedItem !== null && this.highlightedItem + amountToMove <= this.filteredInstances.length - 1) {
                    this.highlightedItem += amountToMove;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'ArrowLeft') {
                if (this.highlightedItem > 0) {
                    this.highlightedItem--;
                } else if (this.highlightedItem === 0) {
                    this.highlightedItem = this.filteredInstances.length - 1;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'ArrowRight') {
                if (this.highlightedItem !== null && this.highlightedItem < this.filteredInstances.length - 1) {
                    this.highlightedItem++;
                } else {
                    this.highlightedItem = 0;
                }
                event.preventDefault();
                this.checkIfInView();
            } else if (keycode === 'Enter') {
                if (typeof this.highlightedItem === 'number') {
                    this.setInstance(this.filteredInstances[this.highlightedItem]);
                    this.blur();
                }

                event.preventDefault();
            }
        },
        checkIfInView () {
            let index = this.highlightedItem || 0; // Contains the item currently in focus

            let element = document.getElementById(this.instanceSelector(index));
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block:    "center",
                    inline:   "start"
                });
            }
        },
        blur () {
            document.querySelector('#dashboard-instance-selector-search')?.blur();
        },
        focus (event) {
            if (event?.type === 'blur' && event.path?.at(0)?.id?.includes('#dashboard-instance-selector-search')) {
                return;
            }

            document.querySelector('#dashboard-instance-selector-search')?.focus();
        },
        instanceClasses (instance, index) {
            let classes = [];

            if (this.highlightedItem === index) {
                classes.push('instance-highlighted');
            }

            if (instance.has_incident && this.can('manage instances')) {
                classes.push('instance-has-incident');
            }

            return classes.join(' ');
        },
        resetHighlightedItem () {
            this.highlightedItem = 0;
            this.checkIfInView();
        },
        instanceSelector (index) {
            return `conneqt-instance-${index}`;
        },
    },
    mounted () {
        this.$store.dispatch('instances/retrieveInstances')
            .then(() => {
                if (localStorage.getItem('current_instance')) {
                    const storedInstance = this.filteredInstances.find(instance => instance.id === parseInt(localStorage.getItem('current_instance')));
                    if (storedInstance) this.setInstance(storedInstance);
                }
            });

        this.focus();
    },
};
</script>

<style lang="scss" scoped>
#dashboard-instance-selector {
    width: 60vw;
    height: 100%;
    margin-inline: auto;
    margin-block-end: -4rem;

    @media screen and (min-width: 901px) and (max-width: 1200px) {
        width: 75vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
    }

    #dashboard-instance-selector-title {
        width: 100%;
        color: #a0aec0;
        font-size: 1.875rem;
        font-weight: 300;
        justify-content: center;
        align-items: center;
        display: flex;
        height: unset;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 900px) {
            height: unset;
            margin-top: 2rem;
            margin-bottom: 4rem;
        }
    }

    input {
        border-radius: 0.375rem;
        margin-bottom: 1rem;
    }

    #instance-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.75rem;
        cursor: pointer;
        max-height: calc(100% - 20vh);
        overflow-y: auto;

        @media screen and (min-width: 901px) and (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 701px) and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            padding: 1rem;
            border: 1px solid transparent;
            border-radius: 0.375rem;
            background-color: #fff;
            transition: all 0.3s ease-in-out;

            &.instance-has-incident {
                border: 2px solid red;
            }

            &.instance-highlighted {
                border-color: #2D3748;

                &:before {
                    content: '>';
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
</style>
