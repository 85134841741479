<template>
    <div id="user-menu" class="group relative cursor-pointer hover:bg-gray-900 z-10" v-if="user" @click="open = !open"
         :class="{ 'bg-gray-900': open }">
        <div id="user-menu-button" class="flex items-center p-4">
            <img :src="'https://s.gravatar.com/avatar/' + emailHash" class="w-10 h-10 rounded-full">
            <div class="ml-3 text-gray-400">
                <span class="block text-xs text-gray-500 uppercase">Logged in as</span>
                {{ user.name }}
            </div>
            <div class="flex items-center justify-center w-6 h-6 bg-gray-600 rounded-full text-gray-800 ml-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-1/2">
                    <path fill="currentColor"
                          d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
                </svg>
            </div>
        </div>
        <div v-if="open" id="user-menu-dropdown"
             class="block absolute left-0 right-0 bg-gray-900 p-2 text-sm rounded-b-none rounded-bl">
            <router-link :to="{ name: 'settings.index' }"
                         class="block w-full p-2 text-gray-500 cursor-pointer rounded hover:bg-gray-300 hover:text-gray-700">
                {{ $t('pages.settings.title') }}
            </router-link>
            <button @click="logout"
                    class="block w-full text-left p-2 text-gray-500 cursor-pointer rounded hover:bg-gray-300 hover:text-gray-700">
                {{ $t('general.actions.logout') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import md5 from 'md5';

    export default {
        name: 'UserMenu',
        data () {
            return {
                open: false
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.user
            }),
            emailHash () {
                return this.user && this.user.email ? md5(this.user.email) : md5('');
            }
        },
        methods: {
            logout () {
                this.$store.dispatch('authentication/logout');
            }
        },
        mounted () {
            document.addEventListener('click', (event) => {
                // Close the menu if the user clicks outside it
                if (!event.target.closest('#user-menu')) {
                    this.open = false;
                }
            });
        }
    };
</script>

<style lang="scss">
#user-menu {
    min-width: 4.5rem;
}

@media screen and (max-width: 900px) {
    #user-menu-button div {
        display: none;
    }

    #user-menu-dropdown {
        right: 0;
        left: unset;

        a {
            padding-right: 1.5rem;
        }
    }
}
</style>
