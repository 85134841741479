import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        retrieveProductDetails ({ commit }) {
            return new Promise((resolve) => {
                api.products.list((result) => {
                    commit('setProductData', result);
                    resolve(result);
                });
            });
        }
    },
    mutations: {
        setProductData (state, productData) {
            state.all = productData;
        }
    }
};