const useCommanderOptions = (router) => {
    return [
        {
            text: 'Dashboard',
            description: 'Conneqt dashboard',
            onSelect: () => {
                router.push({
                    name: 'dashboard',
                });
            }
        },
        {
            text: 'Messages',
            description: 'View messages',
            onSelect: () => {
                router.push({
                    name: 'messages.index',
                });
            }
        },
        {
            text: 'Mappings',
            description: 'Manage mappings',
            onSelect: () => {
                router.push({
                    name: 'mappings.index',
                });
            }
        },
        {
            text: 'Workflows',
            description: 'Manage workflows',
            onSelect: () => {
                router.push({
                    name: 'workflows.index',
                });
            }
        },
        {
            text: 'Schedules',
            description: 'Manage schedules',
            onSelect: () => {
                router.push({
                    name: 'schedules.index',
                });
            }
        },
        {
            text: 'Adapters',
            description: 'Manage adapters',
            onSelect: () => {
                router.push({
                    name: 'adapters.index',
                });
            }
        },
        {
            text: 'Teams',
            description: 'Manage teams',
            onSelect: () => {
                router.push({
                    name: 'teams.index',
                });
            }
        },
    ];
};

export default useCommanderOptions;
