<template>
    <svg class="ml-2 w-3" role="img" viewBox="0 0 512 512"
         xmlns="http://www.w3.org/2000/svg">
        <g class="fa-group">
            <path class="fa-secondary"
                  d="M400 320h32a16 16 0 0 1 16 16v128a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48V112a48 48 0 0 1 48-48h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H64v320h320V336a16 16 0 0 1 16-16z"
                  fill="currentColor"
                  opacity="0.4"></path>
            <path class="fa-primary"
                  d="M512 24v128c0 21.47-26 32-41 17l-35.71-35.71L191.8 376.77a24 24 0 0 1-33.94 0l-22.63-22.63a24 24 0 0 1 0-33.94L378.76 76.68 343.05 41C328 25.9 338.66 0 360 0h128a24 24 0 0 1 24 24z"
                  fill="currentColor"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'OpenLinkIcon',
};
</script>
