Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 -
    3 + (week1.getDay() + 6) % 7) / 7);
};

const D = 'Zondag,Maandag,Dinsdag,Woensdag,Donderdag,Vrijdag,Zaterdag'.split(',');
const M = 'Januari,Februari,Maart,April,Mei,Juni,Juli,Augustus,September,Oktober,November,December'.split(',');
Date.prototype.format = function (format) {
  return format.replace(/a|A|Z|S(SS)?|ss?|mm?|HH?|hh?|D{1,4}|M{1,4}|YY(YY)?|'([^']|'')*'/g, str => {
    let c1 = str.charAt(0);
    const ret = str.charAt(0) === ''
      ? (c1 = 0) || str.slice(1, -1).replace(/''/g, '')
      : str === 'a'
        ? (this.getHours() < 12 ? 'am' : 'pm')
        : str === 'A'
          ? (this.getHours() < 12 ? 'AM' : 'PM')
          : str === 'Z'
            ? (('+' + -this.getTimezoneOffset() / 60).replace(/^\D?(\D)/, '$1').replace(/^(.)(.)$/, '$10$2') + '00')
            : c1 === 'S'
              ? this.getMilliseconds()
              : c1 === 's'
                ? this.getSeconds()
                : c1 === 'H'
                  ? this.getHours()
                  : c1 === 'h'
                    ? (this.getHours() % 12) || 12
                    : (c1 === 'D' && str.length > 2)
                      ? D[this.getDay()].slice(0, str.length > 3 ? 9 : 3)
                      : c1 === 'D'
                        ? this.getDate()
                        : (c1 === 'M' && str.length > 2)
                          ? M[this.getMonth()].slice(0, str.length > 3 ? 9 : 3)
                          : c1 === 'm'
                            ? this.getMinutes()
                            : c1 === 'M'
                              ? this.getMonth() + 1
                              : ('' + this.getFullYear()).slice(-str.length);
    return c1 && str.length < 4 && ('' + ret).length < str.length
      ? ('00' + ret).slice(-str.length)
      : ret;
  });
};

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.subDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

Date.prototype.subMonths = function (months) {
  const date = new Date(this.valueOf());
  date.setMonth(date.getUTCMonth() + months, 1);
  return date;
};

Date.prototype.addMonths = function (months) {
  const date = new Date(this.valueOf());
  date.setMonth(date.getUTCMonth() + months, 1);
  return date;
};
