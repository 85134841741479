import { customRef } from 'vue';

export default function callbackRef (value, callback) {
    return customRef((track, trigger) => {
        return {
            get () {
                track();
                return value;
            },
            set (newValue) {
                trigger();
                value = newValue;

                callback(newValue);
            }
        };
    });
};
