<template>
    <div class="instance-data-backdrop" v-if="showPopup"/>
    <div class="instance-data-popup" v-if="showPopup">
        <div class="instance-data-popup__header">
            <h2 class="text-xl font-bold mb-2">
                {{ $t('conneqt.instances.current_instance') }}
            </h2>

            <div class="close-popup" @click="showPopup = false">
                &times;
            </div>
        </div>

        <dl>
            <dt>
                Instance Name:
            </dt>
            <dd>
                <router-link class="flex" :to="{name: 'instances.details', params: { id: currentInstance.id }}">
                    {{ currentInstance.name || $t('general.unknown') }}

                    <open-link-icon/>
                </router-link>
            </dd>
            <dt>
                IP Address:
            </dt>
            <dd class="can-copy" @click.stop="isSupported ? copy() : undefined"
                @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                {{ currentInstance.ip_address || $t('general.unknown') }}

                <transition>
                    <div class="copy-tooltip" v-if="copied || showTooltip">
                        <template v-if="copied">
                            Copied IP address!
                        </template>
                        <template v-else-if="showTooltip">
                            Click to copy IP address
                        </template>
                    </div>
                </transition>
            </dd>
        </dl>

        <template v-if="currentInstance.ip_address">
            <hr class="mt-6 mb-4">

            <instance-quick-connections :instance="currentInstance"/>
        </template>
    </div>
</template>

<script>
import { mapState, useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useClipboard } from '@vueuse/core';
import OpenLinkIcon from './common/Icons/OpenLinkIcon';
import InstanceQuickConnections from './instances/InstanceQuickConnections';

export default {
    name:       "InstanceDataPopup",
    components: {
        InstanceQuickConnections,
        OpenLinkIcon
    },
    computed:   {
        ...mapState({
            currentInstance: state => state.instances.current,
        }),
    },
    setup () {
        const store       = useStore();
        const route       = useRoute();
        const showPopup   = ref(false);
        const showTooltip = ref(false);
        const source      = computed(() => store.state.instances.current?.ip_address);
        const {
                  text,
                  copy,
                  copied,
                  isSupported
              }           = useClipboard({ source });

        watch(() => route, () => showPopup.value = false, { deep: true });

        onMounted(() => {
            document.addEventListener('keypress', event => {
                if (event.shiftKey && event.code === 'Slash' && !['textarea', 'input'].includes(event.target.localName)) {
                    showPopup.value = !showPopup.value;
                }
            });
        });

        return {
            showPopup,

            text,
            copy,
            copied,
            isSupported,
            showTooltip,
        };
    },
};
</script>

<style lang="scss" scoped>
.instance-data-backdrop {
    &:before {
        content: '';
        position: fixed;
        inset: 0;
        width: 100vmax;
        height: 100vmax;
        background-color: rgba(26, 32, 44, 0.2);
        z-index: 1000;
    }
}

.instance-data-popup {
    top: 50%;
    position: fixed;
    width: 40rem;
    max-width: 90vw;
    height: max-content;
    max-height: 80vh;
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.2rem;
    border-radius: 0.3rem;
    border: 1px solid #d2d6dc;
    background-color: #fff;
    overflow-y: auto;

    &__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .close-popup {
            font-size: 1.5rem;
            cursor: pointer;
        }
    }

    dl {
        display: grid;
        grid-template-columns: 10rem 1fr;
        grid-gap: 0.5rem;
        margin-top: 1rem;

        dt {
            font-weight: 500;
        }

        dd {
            position: relative;

            &.can-copy {
                cursor: pointer;
            }

            .v-enter-active,
            .v-leave-active {
                transition: opacity 0.5s ease;
            }

            .v-enter-from,
            .v-leave-to {
                opacity: 0;
            }

            .copy-tooltip {
                position: absolute;
                left: 50%;
                top: -1.7rem;
                transform: translateX(-50%);
                background-color: #2D3748;
                color: #ffffff;
                width: max-content;
                padding: 0.2rem 0.5rem;
                border-radius: 0.5rem;
            }
        }
    }
}
</style>
