<template>
    <div class="flex flex-row items-stretch bg-gray-800 sticky top-0 z-10 min-h-app-top-bar">
        <div
            :class="{ 'light': (currentInstance && sidebarOpen && isLoggedIn), 'dark': (!currentInstance || !sidebarOpen || !isLoggedIn) }"
            class="flex items-center px-5 logo-wrapper"
        >
            <logo class="w-10" @click="sidebarOpen = !sidebarOpen"/>
        </div>
        <!--<navigator v-show="isLoggedIn"/>-->
        <bread-crumbs v-show="isLoggedIn"/>
        <div v-if="isLoggedIn" class="flex flex-grow justify-end items-center ml-auto">
            <unified-search v-show="false" class="w-1/4 mr-4"/>
            <instance-selector class="border-l border-r border-gray-700 select-none" v-if="currentInstance"/>
            <user-menu class="border-gray-700 select-none"/>
        </div>
    </div>
</template>

<script>
import Navigator from './Navigator';
import UnifiedSearch from './UnifiedSearch';
import Logo from './common/Logo';
import InstanceSelector from './InstanceSelector';
import UserMenu from './UserMenu';
import { mapState } from 'vuex';
import { inject } from 'vue';
import BreadCrumbs from './navigation/BreadCrumbs';

export default {
    name:       "TopBar",
    components: {
        BreadCrumbs,
        UserMenu,
        InstanceSelector,
        Logo,
        UnifiedSearch,
        Navigator,
    },
    computed:   {
        ...mapState({
            currentInstance: state => state.instances.current,
            isLoggedIn:      state => state.authentication.isLoggedIn,
        }),
    },
    setup () {
        const sidebarOpen = inject('sidebarOpen');

        return {
            sidebarOpen,
        };
    }
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
    transition: all 0.3s ease-in-out;

    &.light {
        background-color: #e2e8f0;
    }

    &.dark {
        background-color: #2d3748;
    }
}
</style>
