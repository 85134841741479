<template>
    <svg class="conneqt-logo" viewBox="0 0 315 189" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="a" x1="100%" x2="0%" y1="50%" y2="50%">
                <stop offset="0%" stop-color="#F27B36"/>
                <stop offset="100%" stop-color="#ED495F"/>
            </linearGradient>
            <clipPath id="shimmer-clip-path">
                <path
                    d="M146.915 100.562l-14.838-14.818-.035.043L86.707 40.45 38.492 88.666l48.215 48.214 37.102-37.102 22.974 23.863-44.923 44.923c-8.369 8.37-21.937 8.37-30.306 0L6.809 103.82c-8.37-8.369-8.37-21.937 0-30.306L71.554 8.768c8.369-8.37 21.937-8.37 30.306 0l54.902 54.902.076-.076 22.912 23.108-.016.016 48.215 48.214 48.214-48.214-48.214-48.215-37.178 37.178-22.7-23.32 45.133-45.133c8.369-8.369 21.937-8.369 30.306 0l64.745 64.745c8.37 8.369 8.37 21.937 0 30.306l-64.745 64.746c-8.369 8.368-21.937 8.368-30.306 0l-64.745-64.746a21.672 21.672 0 0 1-1.544-1.717z"
                    transform="matrix(1 0 0 -1 0 188.792)"/>
            </clipPath>
        </defs>
        <g clip-path="url(#shimmer-clip-path)" fill="none" fill-rule="evenodd">
            <path
                d="M146.915 100.562l-14.838-14.818-.035.043L86.707 40.45 38.492 88.666l48.215 48.214 37.102-37.102 22.974 23.863-44.923 44.923c-8.369 8.37-21.937 8.37-30.306 0L6.809 103.82c-8.37-8.369-8.37-21.937 0-30.306L71.554 8.768c8.369-8.37 21.937-8.37 30.306 0l54.902 54.902.076-.076 22.912 23.108-.016.016 48.215 48.214 48.214-48.214-48.214-48.215-37.178 37.178-22.7-23.32 45.133-45.133c8.369-8.369 21.937-8.369 30.306 0l64.745 64.745c8.37 8.369 8.37 21.937 0 30.306l-64.745 64.746c-8.369 8.368-21.937 8.368-30.306 0l-64.745-64.746a21.672 21.672 0 0 1-1.544-1.717z"
                fill="url(#a)" fill-rule="nonzero" transform="matrix(1 0 0 -1 0 188.792)"/>
            <rect class="shimmer" fill="rgba(255, 255, 255, .25)" height="300" width="50"/>
        </g>
    </svg>
</template>

<style lang="scss">
.conneqt-logo {
    animation: scaleIn;
    animation-duration: 1s;
    animation-delay: .2;

    .shimmer {
        transform: rotate(45deg) translateX(100%) translateY(-100%);
        animation: shimmer;
        animation-duration: .4s;
        animation-timing-function: ease-in-out;
        animation-delay: .3s;
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(.75, .75);
    }
    10% {
        transform: scale(1.25, 1.25);
    }
    20% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes shimmer {
    0% {
        transform: rotate(45deg) translateX(0%) translateY(-50%);
    }
    100% {
        transform: rotate(45deg) translateX(100%) translateY(-100%);
    }
}
</style>

<script>
    export default {
        props: {
            animated: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    };
</script>
