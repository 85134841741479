import api from '../../services/api'

export default {
  namespaced: true,
  state: {
    items: [],
    current: null
  },
  actions: {
    retrieveSchedules ({ commit }, instanceId) {
      api.data.list(instanceId, 'schedule', 1, 1, {  }, result => {
        commit('setAll', result)
      })
    },
    retrieveScheduleDetails ({ commit }, { instanceId, scheduleId }) {
      return new Promise((resolve) => {
        api.data.get(instanceId, `schedule/${scheduleId}`, result => {
          commit('setCurrent', result)
          resolve(result)
        })
      })
    },
    createSchedule ({ dispatch }, { instanceId, schedule }) {
      return new Promise((resolve) => {
        api.data.post(instanceId, `schedule`, schedule, result => {
          dispatch('notifications/queueNotification', 'Successfully created schedule', { root: true })
          resolve(result)
        })
      })
    },
    updateSchedule ({ dispatch }, { instanceId, schedule }) {
      return new Promise((resolve) => {
        api.data.put(instanceId, `schedule/${schedule.id}`, { schedule }, result => {
          resolve(result)
          dispatch('notifications/successNotification', 'Successfully updated schedule', { root: true })
        })
      })
    },
    deleteSchedule ({ dispatch }, { instanceId, scheduleId }) {
      return new Promise((resolve) => {
        api.data.delete(instanceId, `schedule/${scheduleId}`, () => {
          dispatch('notifications/queueNotification', 'Successfully deleted schedule', { root: true })
          resolve()
        })
      })
    },
    executeSchedule ({ dispatch }, { instanceId, scheduleId }) {
      return new Promise( (resolve => {
        api.data.get(instanceId, `schedule/${scheduleId}/execute`, () => {
          dispatch('notifications/queueNotification', 'Successfully executed schedule', { root: true })
          resolve()
        })
      }))
    },
    testSchedule (context, { instanceId, workflowId, sampleData, scheduleDefinition }) {
      return new Promise((resolve) => {
        api.data.post(instanceId, 'engine/test-schedule', { workflow_id: workflowId, data: sampleData, schedule: scheduleDefinition}, result => {
          resolve(result)
        })
      })
    }
  },
  mutations: {
    setAll (state, schedules) {
      state.items = schedules
    },
    setCurrent (state, schedule) {
      state.current = schedule
    }
  }
}
