export default {
    namespaced: true,
    state: {
        notifications: []
    },
    actions: {
        queueNotification ({ commit }, message) {
            let timeout = 1000;
            let extraClass = 'z-20 bg-gray-200 text-gray-800';
            if (typeof message === 'object') {
                timeout = (message.timeout > 0) ? message.timeout : timeout;
                extraClass = (message.extraClass) ? message.extraClass : timeout;
                message = message.message;
            }

            let id = Math.random();
            commit('addNotification', { id, message, extraClass });

            setTimeout(() => {
                commit('popNotification', id);
            }, timeout);
        },
        successNotification ({ commit }, message) {
            let timeout = 2000;
            let extraClass = 'bg-green-400 text-white';
            if (typeof message === 'object') {
                timeout = (message.timeout > 0) ? message.timeout : timeout;
                extraClass = (message.extraClass) ? message.extraClass : timeout;
                message = message.message;
            }

            let id = Math.random();
            commit('addNotification', { id, message, extraClass });

            setTimeout(() => {
                commit('popNotification', id);
            }, timeout);
        },
        queueErrorNotification ({ dispatch }, message) {
            let result = '';
            for (const value of Object.values(message)) {
                result += value + '\n';
            }

            dispatch('notifications/queueNotification', {
                message: result,
                timeout: 100000,
                extraClass: 'bg-red-500 text-white'
            }, { root: true });
        }
    },
    mutations: {
        addNotification (state, message) {
            state.notifications.push(message);
        },
        popNotification (state, id) {
            if (state.notifications.length === 1) {
                state.notifications = [];
            } else {
                state.notifications = state.notifications.splice(state.notifications.indexOf(state.notifications.find(n => n.id === id)), 1);
            }
        }
    }
};
