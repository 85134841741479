<template>
    <component :is="currentLayout" v-if="$route">
        <router-view :key="$route.fullPath"/>
    </component>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, watch } from 'vue';
import DefaultLayout from './layouts/DefaultLayout';
import AuthenticationLayout from './layouts/AuthenticationLayout';
import { useRoute } from 'vue-router';
import InstanceSelectionLayout from './layouts/InstanceSelectionLayout';

export default {
    name:       'Control Center',
    components: {
        DefaultLayout,
        AuthenticationLayout,
        InstanceSelectionLayout,
    },
    setup () {
        const route           = useRoute();
        const store           = useStore();
        const { locale }      = useI18n();
        const user            = computed(() => store.state.authentication.user);
        const isLoggedIn      = computed(() => store.state.authentication.isLoggedIn);
        const currentInstance = computed(() => store.state.instances.current);

        watch(() => user, () => {
            if (user?.value?.language?.short_code) {
                locale.value = user.value.language.short_code.split('-').at(0);
            }
        }, {
            immediate: true,
            deep:      true,
        });

        watch(() => isLoggedIn, () => {
            if (!isLoggedIn.value) store.dispatch('authentication/getUserDetails');
        }, {
            deep:      true,
            immediate: true,
        });

        const layouts       = new Map([
            ['DefaultLayout', DefaultLayout],
            ['InstanceSelectionLayout', InstanceSelectionLayout],
            ['AuthenticationLayout', AuthenticationLayout],
        ]);
        const currentLayout = computed(() => {
            if (!isLoggedIn.value) return 'AuthenticationLayout';
            if (!currentInstance.value) return 'InstanceSelectionLayout';

            return layouts.get(`${route.meta.layout || 'default'}Layout`.ucfirst());
        });

        return {
            currentLayout,
            user,
            isLoggedIn,
            currentInstance,
        };
    },
};
</script>

<style lang="scss">
@import "styles/build";

main {
    padding: 3rem 3rem 6rem;
}

@media screen and (max-width: 1200px) {
    main {
        padding: 2rem 1rem 6rem;
    }
}
</style>
