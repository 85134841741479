export default {
    customer:     'Customer',
    subscription: 'Subscription',
    adapter:      'Adapter',
    discount:     'Discount',
    total:        'Total',
    usage:        'Usage',

    subscriptions: {
        subscription_information: 'Subscription Information',
    },

    services: {
        availability: 'Service Availability',
    },

    dashboard: {
        incidents_logbook: 'Logbook',
        incidents_logbook_link: 'Show full logbook',
        no_incidents: 'No logs available',
    },

    entities: {
        unknown:             'Unknown',
        customer:            'Customer',
        product:             'Product',
        order:               'Order',
        stock:               'Stock',
        order_status:        'Order status',
        special_price:       'Special price',
        shipment:            'Shipment',
        invoice:             'Invoice',
        customer_assortment: 'Customer assortment',
        company:             'Company',
        quote:               'Quote',
        credit:              'Credit',
        rma:                 'RMA',
        CUSTOM_1:            'CUSTOM_1',
        CUSTOM_2:            'CUSTOM_2',
        CUSTOM_3:            'CUSTOM_3',
        CUSTOM_4:            'CUSTOM_4',
        CUSTOM_5:            'CUSTOM_5',
    },

    messages: {
        title:         'messages',
        use_as_filter: 'Use as filter',

        search: {
            filters: 'Filters',
            toggle:  'Search',
        },

        filters: {
            show_unhandled: 'Show unhandled messages',
        },

        statistics: {
            message_statistics_of: 'Message Statistics of workflow',
            errors:                'Errors',
            filtered:              'Filtered',
            success:               'Success',
            total:                 'Total',
            not_available:         'No statistics available',
        },

        details: {
            title:            'General Information',
            mark_handled:     'Handled',
            mark_not_handled: 'Not handled',
        },

        error: {
            title: 'Error Information',
        },
    },

    instances: {
        please_select_instance: 'Please select a Conneqt Instance',
        select_instance:        'Select Instance',
        current_instance:       'Current Instance',
        available:              'Available',
    },

    adapters: {
        register_adapter:            'Register adapter',
        identifier:                  'Identifier',
        base_url:                    'Base url',
        base_path:                   'Base path',
        general_settings:            'General Settings',
        configuration:               'Configuration',
        configuration_not_available: 'Adapter configuration not available',

        notifications: {
            updated: 'Successfully updated adapter',
        },
    },

    workflows: {
        name:    'Workflow',
        entity:  'Entity',
        adapter: 'Adapter',
        watcher: 'Watcher',

        filter_options: {
            all_workflows: 'all workflows',
        },
    },

    mappings: {
        name:    'Mapping',
    },
};
