export default {
    customer:     'Klant',
    subscription: 'Abonnement',
    adapter:      'Adapter',
    discount:     'Korting',
    total:        'Totaal',
    usage:        'Verbruik',

    subscriptions: {
        subscription_information: 'Abonnement informatie',
    },

    services: {
        availability: 'Service Beschikbaarheid',
    },

    dashboard: {
        incidents_logbook: 'Logboek',
        incidents_logbook_link: 'Toon alle logs',
        no_incidents: 'Geen logs beschikbaar',
    },

    entities: {
        unknown:             'Onbekend',
        customer:            'Klant',
        product:             'Artikel',
        order:               'Order',
        stock:               'Voorraad',
        order_status:        'Order status',
        special_price:       'Speciale prijs',
        shipment:            'Verzending',
        invoice:             'Factuur',
        customer_assortment: 'Klant assortiment',
        company:             'Bedrijf',
        quote:               'Offerte',
        credit:              'Credit',
        rma:                 'RMA',
        CUSTOM_1:            'CUSTOM_1',
        CUSTOM_2:            'CUSTOM_2',
        CUSTOM_3:            'CUSTOM_3',
        CUSTOM_4:            'CUSTOM_4',
        CUSTOM_5:            'CUSTOM_5',
    },

    messages: {
        title:         'berichten',
        use_as_filter: 'Gebruik als filter',

        search: {
            filters: 'Filters',
            toggle:  'Zoek',
        },

        filters: {
            show_unhandled: 'Toon niet afgehandelde berichten',
        },

        statistics: {
            message_statistics_of: 'Bericht Statistieken van workflow',
            errors:                'Foutmeldingen',
            filtered:              'Gefilterd',
            success:               'Succesvol',
            total:                 'Totaal',
            not_available:         'Geen statistieken beschikbaar',
        },

        details: {
            title:            'Algemene Informatie',
            mark_handled:     'Afgehandeld',
            mark_not_handled: 'Niet afgehandeld',
        },

        error: {
            title: 'Foutmelding Informatie',
        },
    },

    instances: {
        please_select_instance: 'Selecteer een Conneqt Instantie',
        select_instance:        'Selecteer Instantie',
        current_instance:       'Huidige Instantie',
        available:              'Beschikbaar',
    },

    adapters: {
        register_adapter:            'Registreer adapter',
        identifier:                  'Identificatie',
        base_url:                    'Basis url',
        base_path:                   'Basis pad',
        general_settings:            'Algemene instellingen',
        configuration:               'Configuratie',
        configuration_not_available: 'Adapter configuratie is niet beschikbaar',

        notifications: {
            updated: 'Adapter geüpdatet',
        },
    },

    workflows: {
        name:    'Workflow',
        entity:  'Entiteit',
        adapter: 'Adapter',
        watcher: 'Watcher',

        filter_options: {
            all_workflows: 'alle workflows',
        },
    },

    mappings: {
        name:    'Mapping',
    },
};
