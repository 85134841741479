import api from '../../services/api';

export default {
    namespaced: true,
    state: {
        entityTypes: {},
    },
    getters: {
        entityTypes (state) {
            var types = [];
            Object.keys(state.entityTypes).forEach(key => {
                types.push({ id: state.entityTypes[key], description: key });
            });
            return types;
        },
        getEntityTypeById: (state, getters) => (id) => {
            return getters.entityTypes.find(entityType => entityType.id === id);
        }
    },
    actions: {
        getEntityTypes ({ commit }, { instanceId }) {
            api.data.get(instanceId, 'entity_types', entityTypes => {
                commit('setEntityTypes', entityTypes);
            });
        }
    },
    mutations: {
        setEntityTypes (state, entityTypes) {
            state.entityTypes = entityTypes;
        }
    }
};