import apiClient from './baseClient';

function resolveErrorCallback(errorCallback) {
    if (!errorCallback || typeof errorCallback !== 'function') {
        errorCallback = (error) => console.warn(error);
    }

    return errorCallback;
}

export default {
    async get(url) {
        try {
            return await apiClient.get(url);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async post(url, data) {
        try {
            return await apiClient.post(url, data, {headers: {'X-Requested-With': 'XMLHttpRequest'}});
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async put(url, data) {
        try {
            return await apiClient.put(url, data, {headers: {'X-Requested-With': 'XMLHttpRequest'}});
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async delete(url) {
        try {
            return await apiClient.delete(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}});
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    authentication: {
        login(email, password, callback, errorCallback) {
            apiClient.retrieveAccessToken(email, password, callback, errorCallback);
        },
        logout(callback, errorCallback) {
            apiClient.post('/logout', {})
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        forgotPassword(email, callback, errorCallback) {
            apiClient.post('/forgot-password', email)
                .then(({data}) => callback(data))
                .catch((error) => errorCallback(error));
        },
        updatePassword(data, callback, errorCallback) {
            apiClient.post('/update-password', data)
                .then(({data}) => callback(data))
                .catch((error) => errorCallback(error));
        },
        me(callback, errorCallback) {
            apiClient.get('/auth/me')
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    instances:      {
        list(callback, errorCallback) {
            apiClient.get('/instances')
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        adminList(page, pageSize, callback, errorCallback) {
            apiClient.get(`/instances/paged?page_size=${pageSize}&page=${page}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        get(id, callback, errorCallback) {
            apiClient.get(`/instances/${id}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        create(instance, callback, errorCallback) {
            apiClient.post(`/instances/`, instance)
                .then(({data}) => callback(data))
                .catch((error) => errorCallback(error));
        },
        update(instance, callback, errorCallback) {
            apiClient.put(`/instances/${instance.id}`, instance)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
    },
    settings:       {
        changePassword(requestData, callback, errorCallback) {
            apiClient.post('/user/changePassword', requestData)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        updateUser(requestData, callback, errorCallback) {
            apiClient.post('user/update', requestData)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        listNotifications(callback, errorCallback) {
            apiClient.get('/user/notifications')
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        newNotification(notification, callback, errorCallback) {
            apiClient.post('/user/notifications', notification)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        updateNotification(notification, callback, errorCallback) {
            apiClient.put(`/user/notifications/${notification.id}`, notification)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        deleteNotification(notification, callback, errorCallback) {
            apiClient.delete(`user/notifications/${notification.id}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    teams:          {
        list(page, pageSize, callback, errorCallback) {
            apiClient.get('/teams', {
                params: {
                    page,
                    page_size: pageSize
                }
            })
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        get(id, callback, errorCallback) {
            apiClient.get(`/teams/${id}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        create(team, callback, errorCallback) {
            apiClient.post(`/teams/`, team)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        update(team, teamInstances, callback, errorCallback) {
            apiClient.put(`/teams/${team.id}`, {
                team,
                team_instances: teamInstances
            })
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        roles(callback, errorCallback) {
            apiClient.get('/teams/roles')
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        createUser(team, user, callback, errorCallback) {
            apiClient.post(`/teams/${team.id}/users`, user)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error))
        },
        updateUser(team, user, callback, errorCallback) {
            apiClient.put(`/teams/${team.id}/users/${user.id}`, user)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error))
        },
        deleteUser(team, user, callback, errorCallback) {
            apiClient.delete(`/teams/${team.id}/users/${user.id}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    metadata:       {
        getAutocompleteSuggestions(type, callback, errorCallback) {
            apiClient.get(`/metadata/autocomplete?type=${type}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    provisioning:   {
        getEvents(page, pageSize, callback, errorCallback) {
            apiClient.get(`/provisioning/events?page[number]=${page}&page[size]=${pageSize}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        getRunsForInstance(instanceId, callback, errorCallback) {
            apiClient.get(`/instances/${instanceId}/provisioning_runs`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        getDaemonsForInstance(instanceId, callback, errorCallback) {
            apiClient.get(`/provisioning/daemons/${instanceId}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        createDaemonForInstance(instanceId, daemonSettings, callback, errorCallback) {
            apiClient.post(`/provisioning/daemons/${instanceId}`, daemonSettings)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    data:           {
        list(instanceId, resource, page, pageSize, additional, callback, errorCallback) {
            apiClient.post(`/list/${instanceId}/${resource}`, {
                page,
                page_size: pageSize,
                additional
            })
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        get(instanceId, path, callback, errorCallback) {
            apiClient.get(`/get/${instanceId}/${path}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        post(instanceId, path, data, callback, errorCallback) {
            apiClient.post(`/post/${instanceId}/${path}`, {
                post_data: data
            })
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        put(instanceId, path, data, callback, errorCallback) {
            apiClient.put(`/put/${instanceId}/${path}`, {
                post_data: data
            })
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        },
        delete(instanceId, path, callback, errorCallback) {
            apiClient.delete(`/delete/${instanceId}/${path}`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    },
    products:       {
        list(callback, errorCallback) {
            apiClient.get(`/products`)
                .then(({data}) => callback(data))
                .catch((error) => resolveErrorCallback(errorCallback)(error));
        }
    }
};
