import api from '../../services/api';
import router from '../../router';

export default {
    namespaced: true,
    state:      {
        isLoggedIn: false,
        user:       null,
        errors:     null
    },
    getters:    {
        isSuperAdmin (state, getters) {
            return getters.getTokenDetails ? getters.getTokenDetails.super_admin === true : false;
        },
        userPermissions (state) {
            return (state.user && state.user.permissions) ? state.user.permissions : [];
        },
        user (state) {
            return state.user;
        }
    },
    actions:    {
        login ({
                   commit,
                   dispatch
               }, credentials) {
            return new Promise((resolve) => {
                api.authentication.login(credentials.email, credentials.password, () => {
                    commit('setIsLoggedIn', true);
                    dispatch('getUserDetails');
                    resolve();
                }, (error) => {
                    dispatch('notifications/queueErrorNotification', error.response.data.errors, { root: true });
                });
            });
        },
        logout ({ commit }) {
            return new Promise((resolve) => {
                commit('setIsLoggedIn', false);
                commit('setUser', null);
                localStorage.clear();
                router.push('/login');

                // api.authentication.logout(() => {
                    // window.location.reload();
                    //
                    // resolve();
                // });
            });
        },
        forgotPassword ({ dispatch }, email) {
            return new Promise((resolve) => {
                api.authentication.forgotPassword(email, result => {
                    dispatch('notifications/queueNotification', {
                        message:    'If the email is known to us, you should receive a mail very shortly',
                        timeout:    10000,
                        extraClass: 'bg-white'
                    }, { root: true });
                    resolve(result);
                }, error => {
                    dispatch('notifications/queueErrorNotification', error.response.data.errors, { root: true });
                });
            });
        },
        updatePassword ({
                            dispatch,
                            commit
                        }, content) {
            return new Promise((resolve) => {
                api.authentication.updatePassword(content, result => {
                    dispatch('notifications/queueNotification', {
                        message:    'Password successfully changed. You will be redirected to the login screen',
                        timeout:    3000,
                        extraClass: 'bg-white'
                    }, { root: true });
                    router.push('/login');
                    resolve(result);
                }, (error) => {
                    commit('setErrors', error.response.data.errors);
                });
            });
        },
        getUserDetails ({ commit }) {
            return new Promise((resolve) => {
                api.authentication.me(result => {
                    commit('setUser', result);
                    commit('setIsLoggedIn', !!result?.id);
                    resolve(result);
                });
            });
        }
    },
    mutations:  {
        setIsLoggedIn (state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        setUser (state, user) {
            state.user = user;
        },
        setErrors (state, errors) {
            state.errors = errors;
        }
    }
};
