export default {
    fields: {
        name:     'Name',
        email:    'Email address',
        language: 'Language',
        password: 'Password',

        password_change: {
            change:           'Change password',
            current_password: 'Current password',
            new_password:     'New password',
            repeat_password:  'Repeat password',
        },

        languages: {
            select: 'Select language',
        },
    },

    admin: {
        roles: {
            title:  'Rollen & Rechten',
            empty:  'Er zijn geen rollen gevonden',
            add:    'Voeg rol toe',
            save:   'Opslaan',
            delete: 'Verwijder rol',
        },

        versions: {
            title:          'Versiebeheer',
            empty:          'Er zijn geen versies gevonden',
            add:            'Voeg versie toe',
            save:           'Opslaan',
            delete:         'Verwijder versie',
            'create-major': 'Major release',
            'create-minor': 'Minor release',
            'create-patch': 'Patch release',
        },

        modules: {
            application: {
                title:      'Applicatie',
                submodules: {
                    versions: 'Versiebeheer',
                },
            },
            conneqt:     {
                title:      'Conneqt',
                submodules: {
                    workflows:         'Workflows',
                    entity_references: 'Entity references',
                },
            },
            accounts:    {
                title:      'Accountbeheer',
                submodules: {
                    roles_permissions: 'Rollen & Rechten',
                },
            },
        },
    },
};
