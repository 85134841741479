<template>
    <div id="page-breadcrumbs">
        <ul>
            <li @click="goToRoute(breadcrumb)" v-for="(breadcrumb, index) in breadCrumbs" :key="index"
                class="text-gray-600 last-child:text-gray-300 hover:text-gray-300"
            >
                <template v-if="index > 0">
                    <div class="arrow arrow-top"></div>
                    <div class="arrow arrow-bottom"></div>
                </template>

                <div class="breadcrumb-text">
                    {{
                        $t(`breadcrumbs.${breadcrumb.name}`) !== `breadcrumbs.${breadcrumb.name}` ? $t(`breadcrumbs.${breadcrumb.name}`) : breadcrumb.name
                    }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { inject } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "BreadCrumbs",
    setup () {
        const router = useRouter();
        return {
            breadCrumbs: inject('breadCrumbs'),
            goToRoute:   (breadcrumb) => {
                if (breadcrumb.route) router.push(
                    typeof breadcrumb.route === 'object' ? breadcrumb.route : { name: breadcrumb.route }
                );
            },
        };
    }
};
</script>

<style lang="scss" scoped>
#page-breadcrumbs {
    @media screen and (max-width: 800px) {
        display: none;
    }
    
    ul {
        display: flex;
        margin-inline-start: 1rem;
        gap: 0.5rem;
        height: 100%;

        li {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            .breadcrumb-text {
                margin-inline: 1rem;
            }

            .arrow {
                position: absolute;
                border-right: 1px solid currentColor;
                height: 50%;
                width: 0;

                &.arrow-top {
                    top: 0;
                    transform: rotate(-10deg);
                }

                &.arrow-bottom {
                    bottom: 0;
                    transform: rotate(10deg);
                }
            }
        }
    }
}
</style>
